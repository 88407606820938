import React, { useState, useEffect } from 'react';
import door from '../../images/door.png'
import { URL } from "../../utils/constants";
import ymaps from 'ymaps';
import {
  YMaps,
  Map,
  Placemark,
  Polyline,
  SearchControl,
  ObjectManager,
  RulerControl,
  Polygon,
  TypeSelector,
  Panorama
} from '@pbe/react-yandex-maps';


const MapPage = () => {
  const [coordinates, setCoordinates] = useState({ details: [], });
  const [hermodoorcoord, setHermodoorcoord] = useState({ details: [], });
  const [crossway, setCrossway] = useState({ details: [], });
  //const [text, setText] = useState(null);
  const token = localStorage.getItem('auth_token')

  // Возможно это байт, так что потом удали

  //console.log(token)
  console.log(URL)

  useEffect(() => {
    fetch(`${URL}/api/crossway/`,
      {
        method: 'GET'
      }
    )
      .then(response => response.json())
      .then(data => {
        setCrossway({ details: data });
      })
      .catch(error => console.error('Error:', error));
  }, []);

  useEffect(() => {
    fetch(`${URL}/api/stations/`,
      {
        method: 'GET',
        headers: {
          'authorization': token ? `Token ${token}` : null
        }
      }
    )
      .then(response => response.json())
      .then(data => {
        setCoordinates({ details: data });
      })
      .catch(error => console.error('Error:', error));
  }, []);

  useEffect(() => {
    console.log(token)
    fetch(`${URL}/api/hermodoors/`,
      {
        method: 'GET',
        headers: {
          'authorization': token ? `Token ${token}` : null
        }
      }
    )
      .then(response => response.json())
      .then(data => {
        setHermodoorcoord({ details: data });
      })
      .catch(error => console.error('Error:', error));
  }, []);


  const result = coordinates.details.map((element, index) => {
    return <Placemark
      key={index}
      geometry={[element.long, element.lat]}
      properties={{
        balloonContentHeader: `<a href='stations/${element.id}'>${element.name}</a>`,
        balloonContentBody: element.line,
        balloonContentFooter: element.documentation,
        balloonContentHint: element.name,

        //iconContent: `Content`,
      }}
      options={
        {

          draggable: false,
          iconLayout: 'default#image',
          iconImageSize: [20, 20],
          iconImageOffset: [-10, -20],

        }
      }
    //onClick={() => setText(element.name)}
    />;
  });

  const station_sq = coordinates.details.map((element, index) => {
    return <Polygon
      key={index}
      geometry={[element.station_coordinate]}
      options={{
        fillColor: "#5e5b5b",
        strokeColor: element.archive?.length>0?"#ffffff":"#2b2929",
        opacity: 1.0,
        strokeWidth: 5,

      }}
      // replace
      properties={{
        balloonContentHeader: `<a href='stations/${element.id}'>${element.name}</a>`,
        balloonContentBody: `${element.line}`,
        balloonContentFooter: 'Подпись',
      }}
    />;
  });

  const crossways = crossway.details.map((element, index) => {
    return <Polygon
      key={index}
      geometry={[element.crossway_coordinate]}
      options={{
        fillColor: "#808080",
        strokeColor: "#000000",
        opacity: 1.0,
        strokeWidth: 3,

      }}
    // replace

    />;
  });

  const hermodors = hermodoorcoord.details.map((element, index) => {
    console.log(element)
    return <Placemark
      key={index}
      geometry={[element.long, element.lat]}
      properties={{
        balloonContentHeader: `<a href='hermodoors/${element.id}'>${element.name}</a>`,
        balloonContentBody: element.line,
        balloonContentFooter: element.documentation,
        balloonContentHint: element.name,

        //iconContent: `Content`,
      }}
      options={
        {

          draggable: false,
          iconLayout: 'default#image',
          iconImageSize: [20, 20],
          iconImageHref: door,
          iconImageOffset: [-10, -20],

        }
      }
    />;
  });

  

  const modules = ["layout.ImageWithContent", "GeoObjectCollection", "geoObject.addon.balloon", "Placemark"];

  // NY CHE

  //const ymaps = React.useRef(null)
  //console.log(ymaps)

  function CustomSearchProvider(points) {
    this.points = points;
  }

  CustomSearchProvider.prototype.geocode = function (request, options) {
    let deferred = ymaps.current && ymaps.current.vow.defer();
    let geoObjects = ymaps.current && new ymaps.current.GeoObjectCollection();

    let offset = options.skip || 0;
    let limit = options.results || 20;

    let points = [];
    for (let i = 0, l = this.points.length; i < l; i++) {
      let point = this.points[i];
      //point.objects.each(function (p) {
      //  console.log(p);
      //  	if (p.properties.balloonContentHeader.toLowerCase().indexOf(request.toLowerCase()) != -1) {
      //      	points.push(p);
      //  	}
      //})
      if (point.name.toLowerCase().indexOf(request.toLowerCase()) !== -1) {
        points.push(point);
        //console.log(point)
      }
    }

    // При формировании ответа можно учитывать offset и limit.
    points = points.splice(offset, limit);

    // Добавляем точки в результирующую коллекцию.
    for (let i = 0, l = points.length; i < l; i++) {

      let point = points[i],
        coordinates = [point.long, point.lat];


      geoObjects.add(
        new ymaps.current.Placemark(coordinates, {
          name: point.name,
          description: point.line,
          boundedBy: [[point.long, point.lat], [point.long, point.lat]],

        })
      );
    }
    console.log(geoObjects)
    deferred.resolve({
      // Геообъекты поисковой выдачи.
      geoObjects: geoObjects,
      // Метаинформация ответа.
      metaData: {
        geocoder: {
          // Строка обработанного запроса.
          request,
          // Количество найденных результатов.
          found: geoObjects.getLength(),
          // Количество возвращенных результатов.
          results: limit,
          // Количество пропущенных результатов.
          skip: offset
        }
      }
    });
    // Возвращаем объект-обещание.
    return deferred.promise();
  };

  const map = React.createRef();

  const addSearchControlEvents = () => {
    const map = this.map.current;
    console.log(map)

    const searchControl = new ymaps.current.control.SearchControl({
      options: {
        float: "left",
        floatIndex: 300,
        provider: "yandex#search",
        geoObjectStandardPreset: "islands#blueDotIcon",
        placeholderContent: "Поиск мест и адресов",
        maxWidth: 320,
        size: "large"
      }
    });

    map.control.add(searchControl);

    searchControl.events.add("resultselect", function (e) {
      const searchCoords = searchControl.getResponseMetaData().SearchResponse
        .Point.coordinates;
      const display = searchControl.getResponseMetaData().SearchResponse
        .display;

      console.log(searchControl.getResponseMetaData());

      if (display && display === "multiple") {
        map.setCenter([searchCoords[1], searchCoords[0]], 11);
      }
    });
  };

  return (

    <YMaps query={{
      apikey: '520ce6aa-6c0f-4297-9f4a-b0e939f299d1',
      mode: 'debug', load: "Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon,control.SearchControl",
    }}>

      <Map
        width='100vw'
        height='90vh'
        defaultState={{ center: [55.74, 37.62], zoom: 11, type: 'yandex#map', theme: "islands#dark" }} // Зум был 11
        modules={modules}
        options={{
          yandexMapDisablePoiInteractivity: true,
          yandexMapAutoDark: true,
          suppressMapOpenBlock: true,
          suppressObsoleteBrowserNotifier: false,
          //suppressMapOpenBlock: true,
        }}
        instanceRef={map}
        onLoad={(api) => {
          ymaps.current = api;
          // addSearchControlEvents();
        }}
      //style={{ width: '100vw', height: '100vh', filter: 'hue-rotate(180deg) invert(100%) brightness(1)' }}
      >
        <TypeSelector options={{ panoramasItemMode: 'off' }} />

        <RulerControl options={{ float: "left" }} />
        <SearchControl
          options={{
            float: "left",
            //maxWidth: 190,
            // noPlacemark: true,
            provider: "yandex#search", // , new CustomSearchProvider(coordinates.details)
            
            // resultsPerPage: 5
          }}
        //  onSuggest={(suggest) => {
        // console.log(suggest); // Лог результатов поиска
        //  if (suggest.items.length > 0) {
        //  setZoom(12); 
        //  setCenter([suggest.items[0].geometry.coordinates[1], suggest.items[0].geometry.coordinates[0]]); 
        //  }
        //  }}
        />

        <SearchControl
          options={{
            float: "right",
            //maxWidth: 190,
            // noPlacemark: true,
            provider: new CustomSearchProvider(coordinates.details), // , new CustomSearchProvider(coordinates.details)
            
            // resultsPerPage: 5
          }}
        //  onSuggest={(suggest) => {
        // console.log(suggest); // Лог результатов поиска
        //  if (suggest.items.length > 0) {
        //  setZoom(12); 
        //  setCenter([suggest.items[0].geometry.coordinates[1], suggest.items[0].geometry.coordinates[0]]); 
        //  }
        //  }}
        />

        <ObjectManager
          options={{
            clusterize: false,
            gridSize: 150
          }}
          features={null}
          objects={{
            openBalloonOnClick: true,
            preset: "islands#greenDotIcon",
          }}
          modules={[
            "objectManager.addon.objectsBalloon",
            "objectManager.addon.objectsHint",
          ]}
        />

        {/* {result} */}

        <Polyline
          geometry={[
            [55.809622755620154, 37.79858319115997],
            [55.79248430678842, 37.79948441338894],
            [55.79154135140818, 37.799570244077415],
            [55.789969708161735, 37.79950587106107],
            [55.78941357298987, 37.799377125028364],
            [55.789099232172696, 37.79916254830723],
            [55.7887969790029, 37.798862140897576],
            [55.788543084520185, 37.79849736047155],
            [55.788301278705895, 37.79800383401282],
            [55.788095742578605, 37.79725281548863],
            [55.78803529056921, 37.79667345834143],
            [55.787745119606335, 37.78122393441561],
            [55.78772093858901, 37.77759758782747],
            [55.78781766254265, 37.77399269891145],
            [55.78798692888122, 37.770988624814784],
            [55.78859144548319, 37.763585727933624],
            [55.7887002574722, 37.76197640252468],
            [55.788736528067375, 37.76096789193509],
            [55.78868816726627, 37.758392971280784],
            [55.78843427207133, 37.749230545285926],
            [55.78819246557243, 37.746441047910444],
            [55.78790229579163, 37.744424026731245],
            [55.78750330879885, 37.74223534417508],
            [55.78701968269334, 37.740389984372825],
            [55.78567758868666, 37.73590533089993],
            [55.78468610203277, 37.73249356103298],
            [55.7840210662923, 37.72946802926418],
            [55.783609947591124, 37.72584168267605],
            [55.78315045682217, 37.71906105828641],
            [55.78297512326592, 37.715434711697995],
            [55.78289047925462, 37.71367518258422],
            [55.78276955891815, 37.71127192330689],
            [55.78264863820479, 37.70925490212769],
            [55.78251562498483, 37.707795780423574],
            [55.78224959717055, 37.7061435396704],
            [55.78168125892443, 37.70350424599976],
            [55.780786411621825, 37.699727695706784],
            [55.78008503039024, 37.697324436429426],
            [55.77966177696159, 37.696122806790775],
            [55.7786338565504, 37.69361225915279],
            [55.774836361545795, 37.68475024056759],
            [55.77232061883247, 37.678870838406944],
            [55.771437651242934, 37.676617782834434],
            [55.77053047184111, 37.67466513467158],
            [55.76928457757593, 37.6725408251318],
            [55.76780880641392, 37.670223396542895],
            [55.766623310260634, 37.668592613461826],
            [55.7650143647184, 37.66659704995476],
            [55.76335696011758, 37.66488043618522],
            [55.76207454011846, 37.663700264218676],
            [55.760235524324145, 37.66217676949821],
            [55.759775756766416, 37.66166178536734],
            [55.759352281831454, 37.660996597531664],
            [55.75903769746165, 37.66033140969596],
            [55.758711010995604, 37.65943018746697],
            [55.75826332507854, 37.65814272713982],
            [55.75776723464947, 37.656340282681754],
            [55.75741633759706, 37.65462366891224],
            [55.75712593763871, 37.652220409634886],
            [55.75692023635435, 37.649087589505484],
            [55.7568718358875, 37.64541832757308],
            [55.75705333729722, 37.64239279580429],
            [55.75750103716327, 37.63915268731429],
            [55.75773093508883, 37.63732878518417],
            [55.75791243248978, 37.63464657616926],
            [55.75791243248348, 37.63215748620346],
            [55.7578640332595, 37.63041941476179],
            [55.75762203623482, 37.627951782468095],
            [55.75724693786636, 37.62518374276472],
            [55.756714533961556, 37.62217966866805],
            [55.75630312593885, 37.62003390145615],
            [55.7557102067193, 37.61735169244124],
            [55.7551656810949, 37.614926975491784],
            [55.75459694618061, 37.61265246224714],
            [55.75401610192749, 37.610270660641916],
            [55.75275757623967, 37.60505644631698],
            [55.751559518715005, 37.59990660500838],
            [55.75005861902149, 37.59364454736205],
            [55.74765019752522, 37.583452153105405],
            [55.74735972454209, 37.58158533563105],
            [55.74659722262816, 37.57757275094475],
            [55.74516900416757, 37.56971924294915],
            [55.74422489873922, 37.5638398407885],
            [55.741876639590416, 37.55229561318842],
            [55.73852346688348, 37.5359234093615],
            [55.73738550136128, 37.52907841195547],
            [55.73705863276581, 37.52435772408926],
            [55.73688914426362, 37.52049534310781],
            [55.73693756961912, 37.51540987481558],
            [55.737107057910116, 37.51174061288321],
            [55.737143376727175, 37.50929443826157],
            [55.73701020754234, 37.50652639855822],
            [55.73673201177468, 37.50439850640598],
            [55.736308285572356, 37.50251023125949],
            [55.735630314039724, 37.50023571801485],
            [55.73472229792544, 37.497574966672076],
            [55.73398376250318, 37.4949142153293],
            [55.732724587796994, 37.48867003274263],
            [55.73116267017947, 37.48000113320645],
            [55.729588581187905, 37.470752876523115],
            [55.72909212450953, 37.46727673363982],
            [55.72905579816265, 37.46311394524869],
            [55.7292132120873, 37.46049610925016],
            [55.729649124244546, 37.45792118859586],
            [55.73038774201963, 37.45463816476164],
            [55.73090839856938, 37.451870125058264],
            [55.731138453896016, 37.4498745615512],
            [55.73090839856938, 37.44747130227385],
            [55.73041195875201, 37.44397370171842],
            [55.72993972962135, 37.439574878934],
            [55.72989129554066, 37.438437622311696],
            [55.729976055142274, 37.43719307732878],
            [55.73019400755499, 37.43609873605071],
            [55.73048460887112, 37.435068767788984],
            [55.73259140344653, 37.42953268838225],
            [55.733136245522886, 37.42841688943205],
            [55.733838475164475, 37.42740837884245],
            [55.734805580816015, 37.426260955174776],
            [55.736246286757314, 37.42475891812644],
            [55.73683950309338, 37.42407227261861],
            [55.73754166583024, 37.42310667737327],
            [55.73818328620403, 37.422076709111536],
            [55.74158616017518, 37.415175862548914],
            [55.74266347999036, 37.41315884136972],
            [55.743486580619454, 37.41215033078012],
            [55.7440554782167, 37.411635346649255],
            [55.74466067927623, 37.41122765087894],
            [55.74580602654174, 37.41071315448454],
            [55.74717370590535, 37.410198170353674],
            [55.75337002150884, 37.40863176028895],
            [55.76089624713911, 37.40753741901087],
            [55.764465249384166, 37.4070009772079],
            [55.7659774395197, 37.40667911212611],
            [55.76753795797665, 37.406164127995254],
            [55.7686299090051, 37.40553379697709],
            [55.769853872435746, 37.40467976613203],
            [55.77042238391725, 37.40418623967327],
            [55.77116475223081, 37.40349469412506],
            [55.77225334717989, 37.40233597983062],
            [55.77347495626673, 37.401027061831364],
            [55.77463605401505, 37.39999709356963],
            [55.77588177650699, 37.39913878668482],
            [55.776982333017756, 37.398623802553956],
            [55.778034484235036, 37.39838776816067],
            [55.779159166059436, 37.39836631048854],
            [55.78019916540431, 37.39858088720972],
            [55.781420524386846, 37.39907441366843],
            [55.783384755952184, 37.400284414582266],
            [55.79056665640412, 37.4062067320871],
            [55.79670769832013, 37.41133511572357],
            [55.79754174120489, 37.41200030355922],
            [55.79837576615718, 37.41249383001796],
            [55.79896803385369, 37.41272986441127],
            [55.79954820568295, 37.412858610444005],
            [55.80008002223382, 37.41277277975551],
            [55.80057557200006, 37.412515287690084],
            [55.801107374459654, 37.41202176123135],
            [55.801409531698454, 37.41154969244472],
            [55.801796289528006, 37.410841589264784],
            [55.802050097256966, 37.41019785910123],
            [55.80249727826056, 37.4085027030038],
            [55.80391130316126, 37.40208685904018],
            [55.80636457443485, 37.39039242773526],
            [55.806678775297144, 37.38914788275229],
            [55.80708964951343, 37.388032083802116],
            [55.8075488567191, 37.387109403900986],
            [55.808285993752726, 37.38610089331139],
            [55.808986865144455, 37.385521536164156],
            [55.80969980752304, 37.385049467377556],
            [55.810569821134095, 37.3846632292794],
            [55.81182647297783, 37.38442719488609],
            [55.81313141446563, 37.38442719488609],
            [55.81499208836907, 37.3846632292794],
            [55.82010244075625, 37.38534987478721],
            [55.82126214320752, 37.38541424780358],
            [55.82286875694613, 37.38545716314776],
            [55.82376263349029, 37.38539279013142],
            [55.83167374963912, 37.3848563483284],
            [55.832434580975594, 37.384663229279326],
            [55.83321955001493, 37.384319906525405],
            [55.834016579227324, 37.383740549378196],
            [55.835506128745415, 37.382011561205495],
            [55.836520466816815, 37.38078847389468],
            [55.83730535304107, 37.37958684425603],
            [55.8381385225407, 37.378406672289465],
            [55.84019118250546, 37.374844698717695],
            [55.84161590601921, 37.372055201342164],
            [55.84330618795362, 37.36825719337708],
            [55.84572074840806, 37.36220612983949],
            [55.8471090524641, 37.35879435997254],
            [55.84796615445839, 37.35701337318665],
            [55.848593879089286, 37.35596194725282],
            [55.849354377983744, 37.354910521318956],
            [55.85012693298794, 37.35418096046691],
            [55.8508391184646, 37.35368743400815],
            [55.851478867130794, 37.35338702659847],
            [55.85219102773487, 37.353172449877285],
            [55.853048017188385, 37.35302224617244],
            [55.853723939144075, 37.35312953453305],
            [55.85452054629175, 37.353365568926364],
            [55.85511195614253, 37.35362306099179],
            [55.85590853470912, 37.35403075676204],
          ]}
          options={{
            strokeColor: '0078BE',
            strokeWidth: 8,
            strokeOpacity: 0.8,
          }}
          // replace
          properties={{
            balloonContentBody: `Арбатско-Покровская линия`,
            balloonContentFooter: 'Подпись',
          }}
        />

        <Polyline
          geometry={[
            [55.814592448440266, 37.734180454543946],
            [55.81223636095986, 37.742763523391595],
            [55.8118497072812, 37.743772033981195],
            [55.811475132851406, 37.744351391128426],
            [55.81111263802083, 37.744780544570794],
            [55.81060513956211, 37.745080951980476],
            [55.81009763445175, 37.745166782668896],
            [55.80936053187084, 37.745166782668896],
            [55.807451250166146, 37.74510240965256],
            [55.80323357943856, 37.74490929060348],
            [55.80272597781439, 37.74484491758711],
            [55.80237548709993, 37.744737629226535],
            [55.80204916531056, 37.74456596784956],
            [55.80179535757551, 37.74428701811203],
            [55.8014569446775, 37.743814949325404],
            [55.80075593713509, 37.74246311598189],
            [55.800224129855096, 37.74104690962203],
            [55.799946136783, 37.739974026016064],
            [55.799728575597136, 37.73894405775436],
            [55.7980605659429, 37.72890186720256],
            [55.79683973079725, 37.72115564756756],
            [55.796513362409385, 37.71885967665081],
            [55.79629578196236, 37.71643495970135],
            [55.796102376095675, 37.713945869735525],
            [55.796102376095675, 37.71182156019572],
            [55.796223254875386, 37.71006203108195],
            [55.79639248453399, 37.70830250196819],
            [55.79670676622631, 37.70617819242839],
            [55.79680346777307, 37.70508385115032],
            [55.79683973079092, 37.70396805220012],
            [55.79681555544945, 37.702980999282644],
            [55.79669467851601, 37.70186520033244],
            [55.796513362409385, 37.700706486038],
            [55.79622325486906, 37.699097160629066],
            [55.79586061739953, 37.69763803892497],
            [55.79542544795977, 37.69639349394206],
            [55.79486939101367, 37.695148948959144],
            [55.7940836447793, 37.69326067381266],
            [55.79358801204352, 37.691844467452796],
            [55.79322534993116, 37.69075012617473],
            [55.792814328770795, 37.689484123519705],
            [55.79164168563082, 37.68575048857092],
            [55.79084378446675, 37.683433059982065],
            [55.79038437938083, 37.682231430343386],
            [55.78996123830354, 37.68143749647497],
            [55.78965899185069, 37.68087959699987],
            [55.78907867205918, 37.680021290115114],
            [55.78842580191581, 37.67918444090246],
            [55.78777292078388, 37.67841196470618],
            [55.78583428144194, 37.676105686265245],
            [55.78452842095438, 37.674517818528415],
            [55.78393593271895, 37.673659511643656],
            [55.78351272129665, 37.67299432380798],
            [55.78254536356714, 37.671449371415385],
            [55.78145705729108, 37.66949672325255],
            [55.78021151374765, 37.667072006303094],
            [55.77925616685836, 37.66499061210753],
            [55.77851847785087, 37.66329545601012],
            [55.77767193220127, 37.66142863853574],
            [55.77709143308223, 37.66007680519225],
            [55.776051350437506, 37.65769500358697],
            [55.77508380667779, 37.65533465965388],
            [55.774708876986246, 37.6544763527691],
            [55.77439441703269, 37.653918453294],
            [55.774079954531224, 37.65348929985163],
            [55.77372920488908, 37.65306014640924],
            [55.773184931927396, 37.65269536598321],
            [55.77225360271133, 37.65224475486872],
            [55.77106824229702, 37.65170831306575],
            [55.76991913386476, 37.65091437919728],
            [55.7693506150126, 37.65035647972218],
            [55.768927244500865, 37.649777122574974],
            [55.768649027078965, 37.64921922309987],
            [55.76834661457614, 37.647996135789086],
            [55.767923233114246, 37.645871826249284],
            [55.767487750221804, 37.64411229713551],
            [55.76723371627851, 37.64344710929983],
            [55.766713546350665, 37.64218110664481],
            [55.76622966109062, 37.64112968071095],
            [55.76554011416784, 37.63986367805588],
            [55.765092506982555, 37.63913411720383],
            [55.764415037642785, 37.63816852195847],
            [55.763507694115795, 37.636945434647686],
            [55.762733410871846, 37.63595838173022],
            [55.762164786781234, 37.63520736320604],
            [55.761571957054336, 37.634349056321284],
            [55.761027513617734, 37.63338346107592],
            [55.76049516154285, 37.632053085404536],
            [55.760107991813335, 37.63057250602831],
            [55.759853909580436, 37.62885589225877],
            [55.75957891349778, 37.62647658269335],
            [55.75911913816568, 37.62330084721974],
            [55.75888924845824, 37.621648606466565],
            [55.75855046114559, 37.619674500631604],
            [55.758223770580614, 37.61832266728809],
            [55.75790917706868, 37.617335614370596],
            [55.75753408147368, 37.616520222830076],
            [55.75689278061276, 37.61538296620777],
            [55.75650557496567, 37.614782151388425],
            [55.75609416473566, 37.61420279424122],
            [55.75519872739245, 37.61312991063523],
            [55.75484780713217, 37.612765130209176],
            [55.7543153703376, 37.61237889211105],
            [55.7522581595966, 37.61081248204635],
            [55.750648618753374, 37.6096323100798],
            [55.748905882788016, 37.608194646047814],
            [55.747296203038545, 37.606628235983116],
            [55.74581959604593, 37.604739960836625],
            [55.74468184417572, 37.602830228018036],
            [55.74424610059593, 37.60218649785446],
            [55.743725622693255, 37.60145693700241],
            [55.743205137818464, 37.600899037527306],
            [55.74226098466986, 37.59997635762618],
            [55.7414015432683, 37.599225339102006],
            [55.74045734629486, 37.59845286290572],
            [55.73930733180771, 37.59755164067668],
            [55.73841150747424, 37.596822079824626],
            [55.73724932621592, 37.5958993999235],
            [55.73645030644056, 37.59525566975992],
            [55.73500960806196, 37.59398966710491],
            [55.733241955584354, 37.59192973058147],
            [55.73287872935939, 37.591436204122715],
            [55.73194643319533, 37.58995562474651],
            [55.731159412535064, 37.58856087605876],
            [55.730578217819385, 37.58748799245279],
            [55.729682192275035, 37.58570700566691],
            [55.728822472676804, 37.58390456120892],
            [55.72820491588002, 37.58246689717693],
            [55.727514693733504, 37.58090048711223],
            [55.72655804978313, 37.578711804556086],
            [55.726243199794496, 37.57776766698283],
            [55.72568615126101, 37.57594376485271],
            [55.72537129421177, 37.57463484685343],
            [55.725020104495044, 37.572553452657885],
            [55.72464469129013, 37.56980687062664],
            [55.72439037705967, 37.56785422246379],
            [55.723918074790106, 37.56577282826822],
            [55.723433656246485, 37.564335164236255],
            [55.722973453040204, 37.56341248433513],
            [55.72230735980562, 37.56255417745037],
            [55.72160492185228, 37.56201773564738],
            [55.720672355309596, 37.561652955221355],
            [55.719872994758774, 37.56158858220501],
            [55.719352190402425, 37.56161003987712],
            [55.718734483178125, 37.561738785909824],
            [55.71522184382078, 37.56272583882732],
            [55.71468886392417, 37.56285458486003],
            [55.71424067063103, 37.56287604253216],
            [55.713744017976985, 37.56279021184366],
            [55.713174676645494, 37.56255417745037],
            [55.71265378259586, 37.56216793935222],
            [55.711939056153305, 37.56130963246746],
            [55.71136968840362, 37.56058007161541],
            [55.70614807638303, 37.55354195516033],
            [55.702367725059915, 37.54811316411418],
            [55.69912020696578, 37.54339247624797],
            [55.69764176941747, 37.541332539724536],
            [55.69720549842637, 37.54066735188883],
            [55.69670862828602, 37.53995924870892],
            [55.6959451325384, 37.53886490743084],
            [55.69502407011865, 37.537534531759455],
            [55.69369091485703, 37.53541022221965],
            [55.69084265746226, 37.53049641530437],
            [55.68746083874543, 37.524617013143676],
            [55.68508490513094, 37.52036839406409],
            [55.68284218172876, 37.516398724722045],
            [55.681435867655296, 37.51386671941198],
            [55.679714276121466, 37.510862645315314],
            [55.67822297686891, 37.508137520956176],
            [55.675494842680216, 37.50326662938513],
            [55.673190936115994, 37.498824891256476],
            [55.671117303700036, 37.494597729849],
            [55.66912845313876, 37.490220364736714],
            [55.668437182522375, 37.488825616048956],
            [55.667661004407414, 37.487237748312126],
            [55.66649670824442, 37.48543530385414],
            [55.66544153479691, 37.48431950490394],
            [55.66432568811454, 37.48346119801918],
            [55.66208176925044, 37.48240977208534],
            [55.65846696881119, 37.48084336202064],
            [55.65181874252184, 37.478161153005736],
            [55.650047308469496, 37.47745304982582],
            [55.648761148390925, 37.47672348897375],
            [55.647911774136716, 37.4760797588102],
            [55.64724439567179, 37.47530728261389],
            [55.64660127470809, 37.47414856831947],
            [55.64606735490685, 37.47298985402504],
            [55.64520578707878, 37.47112303655063],
            [55.63862812091675, 37.454815205740076],
            [55.635812249482136, 37.447841462301355],
            [55.635193216010165, 37.44633942525302],
            [55.63457417271545, 37.44490176122103],
            [55.63333605665749, 37.44209080617342],
            [55.6322678465251, 37.43988066594515],
            [55.63104179657664, 37.43764906804476],
            [55.63016775725028, 37.43623286168491],
            [55.629330117856966, 37.43494540135776],
            [55.62735127511766, 37.4320700732938],
            [55.624753132913234, 37.42831498067293],
            [55.62344185528417, 37.426426705526445],
            [55.62183912279633, 37.42410927693759],
            [55.6203334656586, 37.421770390676606],
            [55.619835614634745, 37.42076188008701],
            [55.619192041706754, 37.41932421605502],
            [55.618232734056136, 37.41687804143343],
            [55.617819860218546, 37.41578370015535],
            [55.61750413021987, 37.4150326816312],
            [55.617018386774106, 37.41395979802523],
            [55.61648406200297, 37.41295128743563],
            [55.61592544192279, 37.41198569219027],
            [55.61541539052084, 37.4113419620267],
            [55.61457743446572, 37.41063385884676],
            [55.61367873696384, 37.409947213338945],
            [55.609063478010576, 37.40640669743928],
            [55.606500559191446, 37.4044325916043],
            [55.60611185539348, 37.40411072652251],
            [55.60543161443176, 37.40387469212919],
            [55.6044355259417, 37.40389614980133],
            [55.60379169913304, 37.40423947255522],
            [55.602989937704145, 37.404947575735164],
            [55.60254045818854, 37.40559130589874],
            [55.601483553542195, 37.4074581233731],
            [55.597207049702135, 37.414689358877204],
            [55.59666030381309, 37.41554766576196],
            [55.59624720184456, 37.4161484805813],
            [55.59557893941207, 37.417092618154555],
            [55.592504784992315, 37.420997914480246],
            [55.59195797329772, 37.42179184834863],
            [55.59136254741655, 37.42277890126613],
            [55.59059698652328, 37.42400198857691],
            [55.59001369196625, 37.42509632985498],
            [55.5886040275022, 37.42782145421412],
            [55.58810577186734, 37.428915795492195],
            [55.587607509879994, 37.43011742513087],
            [55.587145700414496, 37.43138342778589],
            [55.585687318908256, 37.435245808767334],
            [55.58292839837906, 37.442605790304206],
            [55.58244222480174, 37.4435070125332],
            [55.58187096312158, 37.44457989613917],
            [55.58125107398151, 37.44545966069606],
            [55.580230058668604, 37.446532544302],
            [55.57943996899835, 37.4470904437771],
            [55.57856477410784, 37.44782000462915],
            [55.57787189759081, 37.44835644643214],
            [55.576777857035836, 37.44936495702174],
            [55.57578103787636, 37.450630959676765],
            [55.57499085829021, 37.45196133534815],
            [55.57434654619312, 37.4532487956753],
            [55.57366575206422, 37.45449334065821],
            [55.57330103604783, 37.45517998616602],
            [55.572425703723006, 37.45627432744409],
            [55.57164761410092, 37.45708971898461],
            [55.57086950899644, 37.45764761845972],
            [55.569787430849864, 37.458420094656],
            [55.568377036860895, 37.4595358936062],
            [55.56725841235729, 37.46050148885157],
            [55.56598168224588, 37.46168166081809],
            [55.56511834588944, 37.462582883047084],
            [55.56436443095007, 37.4633768169155],
            [55.563294333183116, 37.46447115819357],
            [55.56239445558351, 37.465522584127406],
            [55.561543200963264, 37.46663838307761],
            [55.56015683235514, 37.4685481158962],
          ]}
          options={{
            strokeColor: 'EF161E',
            strokeWidth: 8,
            strokeOpacity: 0.8,
            //strokeStyle: {
            //  style: 'dot',
            //  offset: 10
            //}
          }}
          // replace
          properties={{
            balloonContentBody: `Сокольническая линия`,
            balloonContentFooter: 'Подпись',
          }}
        />

        <Polyline
          geometry={[
            [55.88759028649517, 37.66156769209278],
            [55.88472005131256, 37.661353115371575],
            [55.88295921331704, 37.6613745730437],
            [55.882187313838216, 37.661632065109124],
            [55.88160837911334, 37.662125591567886],
            [55.878050152384134, 37.66555881910694],
            [55.87691627572435, 37.666524414352224],
            [55.87648427803011, 37.66680470519427],
            [55.87606207670317, 37.66686907821064],
            [55.875446860801674, 37.666847620538505],
            [55.874855761792986, 37.666761789850035],
            [55.87413195478113, 37.66650429778461],
            [55.8629145317111, 37.66095586111682],
            [55.86045277259796, 37.65975423147814],
            [55.85946319783464, 37.65923924734728],
            [55.85883564950327, 37.65863843252794],
            [55.8580753369262, 37.657715752626814],
            [55.855661547195396, 37.65348859121934],
            [55.85074902004396, 37.644605114961976],
            [55.84882970612137, 37.64112897207868],
            [55.848069196917145, 37.64001317312848],
            [55.84720002524224, 37.63926215460433],
            [55.846306689570135, 37.63866133978499],
            [55.84563063805875, 37.63827510168684],
            [55.84337302334863, 37.63726659109724],
            [55.84275728739557, 37.63707347204816],
            [55.84199665887306, 37.63694472601545],
            [55.84109112924085, 37.63705201437605],
            [55.83981127790464, 37.6373309641136],
            [55.839328304134376, 37.63748116781844],
            [55.832988714979656, 37.63947673132551],
            [55.828387334585294, 37.64069981863629],
            [55.824099467141515, 37.64132209112776],
            [55.823084803703935, 37.64132209112776],
            [55.817080834589134, 37.6403564958824],
            [55.80871963375807, 37.63891883185038],
            [55.79592060374066, 37.63670869162211],
            [55.78801427632037, 37.635335400606486],
            [55.78634576277405, 37.634906247164096],
            [55.78455626268681, 37.6342839746726],
            [55.77901796242149, 37.63185925772314],
            [55.77777234058763, 37.63127990057588],
            [55.77685321535049, 37.63093657782196],
            [55.77613966890648, 37.630807831789255],
            [55.775268882589934, 37.63087220480563],
            [55.77448273927245, 37.63110823919894],
            [55.7737933389504, 37.631430104280724],
            [55.772245167284616, 37.63248153021456],
            [55.766910759173854, 37.6361078768027],
            [55.76585830637284, 37.63655848791719],
            [55.764926801357134, 37.636858895326846],
            [55.76395898026502, 37.636966183687456],
            [55.7631121170187, 37.63688035299898],
            [55.76220474303638, 37.636665776277745],
            [55.76136994025028, 37.63619370749112],
            [55.76075290056842, 37.63563580801601],
            [55.76005115724907, 37.63475604345915],
            [55.7585750350805, 37.63226695349333],
            [55.75811524785616, 37.63160176565759],
            [55.75771595453976, 37.63132281592004],
            [55.75724405714546, 37.63121552755944],
            [55.75677215401696, 37.631430104280646],
            [55.75619134229463, 37.63196654608362],
            [55.75385590748144, 37.63398356726282],
            [55.75345657036524, 37.63432689001669],
            [55.75206490895793, 37.6351208238851],
            [55.749692918951894, 37.636365368867985],
            [55.7487489234486, 37.63679452231035],
            [55.74774439022724, 37.637052014375776],
            [55.74661879804884, 37.63707347204788],
            [55.74549317325856, 37.63677306463822],
            [55.74462169941037, 37.63623662283525],
            [55.74395597705161, 37.63554997732744],
            [55.74341128663756, 37.63471312811479],
            [55.74293921543554, 37.63376899054153],
            [55.74253976609259, 37.632717564607695],
            [55.74203137007446, 37.63100095083813],
            [55.7416440162674, 37.629155591035875],
            [55.741426128054044, 37.6274818926106],
            [55.74123244861651, 37.62516446402172],
            [55.74101455809537, 37.62385554602246],
            [55.740796666352466, 37.622911408449205],
            [55.7403608791951, 37.62168832113838],
            [55.73998561412107, 37.62091584494209],
            [55.73957402890525, 37.620272114778516],
            [55.73919875623274, 37.61977858831978],
            [55.73856925845073, 37.61913485815621],
            [55.73321811559655, 37.614221051240925],
            [55.731305084862555, 37.612547352815646],
            [55.73020323329762, 37.611431553865444],
            [55.72940406866662, 37.61068053534126],
            [55.728931827298865, 37.6102513818989],
            [55.728277945165466, 37.60986514380074],
            [55.727539287298235, 37.60958619406319],
            [55.72677639641481, 37.609500363374714],
            [55.72585606368117, 37.60939307501411],
            [55.720575839106495, 37.60866351416204],
            [55.719909704801914, 37.608491852785065],
            [55.71941312453335, 37.60827727606389],
            [55.71885597814758, 37.6079554109821],
            [55.71829882378008, 37.60756917288394],
            [55.717668987492466, 37.607011273408865],
            [55.71693012807743, 37.60621733954045],
            [55.71616702902942, 37.60510154059025],
            [55.71567392895695, 37.604320254641706],
            [55.71497137118203, 37.60303279431456],
            [55.71399019167405, 37.60088702710265],
            [55.71322703494544, 37.59902020962829],
            [55.712887849371164, 37.59805461438293],
            [55.712536546908495, 37.59713193448181],
            [55.712173127229214, 37.59612342389221],
            [55.711749133312246, 37.594986167269894],
            [55.711288791827144, 37.59372016461485],
            [55.71063461297746, 37.5919606355011],
            [55.71021060228558, 37.59088775189508],
            [55.709471601187396, 37.589321341830384],
            [55.70882950686907, 37.58814116986384],
            [55.70799355687761, 37.586767878848214],
            [55.70687892894866, 37.584943976718094],
            [55.70605505299935, 37.583656516390946],
            [55.705303856782535, 37.58271237881769],
            [55.70463746090284, 37.58193990262141],
            [55.703656020735565, 37.581060138064515],
            [55.70277149172309, 37.58033057721247],
            [55.701802121769745, 37.579622474032554],
            [55.700105666240965, 37.57863542111503],
            [55.69831218999098, 37.57773419888601],
            [55.69676100874094, 37.57709046872241],
            [55.69504009471521, 37.57651111157518],
            [55.692882785161494, 37.57593175442797],
            [55.69014355694473, 37.575245108920164],
            [55.68939205356031, 37.57492324383832],
            [55.688773870552836, 37.5745370057402],
            [55.68802234073632, 37.573743071871775],
            [55.6869919940244, 37.572648730593706],
            [55.67848149985759, 37.56344338925458],
            [55.67734177680954, 37.56228467496012],
            [55.67254002421613, 37.557220664339994],
            [55.66263150770594, 37.54649182828043],
            [55.657063683642704, 37.54052659543126],
            [55.65200464410963, 37.534883227663855],
            [55.64830397168133, 37.53080626996122],
            [55.646884276252095, 37.529218402224366],
            [55.646350360325606, 37.5287034180935],
            [55.645682955137524, 37.52812406094629],
            [55.644360245651434, 37.52707263501245],
            [55.64205449800394, 37.525356021242914],
            [55.64000348159133, 37.523918357210896],
            [55.637163434665275, 37.521858420687465],
            [55.63455380756189, 37.519970145540974],
            [55.63126421492693, 37.51760980160786],
            [55.629795340457996, 37.516236510592236],
            [55.62770725844129, 37.51366158993794],
            [55.624781327644534, 37.509756293612256],
            [55.62405284603816, 37.508833613711126],
            [55.62342148431367, 37.50823279889182],
            [55.62282653795751, 37.507782187777295],
            [55.62201302520054, 37.507395949679164],
            [55.62130877658417, 37.50722428830219],
            [55.62051951734555, 37.50731011899069],
            [55.61974238496404, 37.50771781476095],
            [55.61804235393269, 37.50906964810444],
            [55.61590506702935, 37.510915007906696],
            [55.61405913416748, 37.51293202908589],
            [55.61193377443636, 37.51595756085469],
            [55.61086497839847, 37.51784583600117],
            [55.61000263299861, 37.51962682278706],
            [55.60912812244048, 37.52183696301533],
            [55.60843578769249, 37.52389689953877],
            [55.60790134545127, 37.525828090029464],
            [55.60699034750945, 37.52979775937149],
            [55.606310121855174, 37.532952037173025],
            [55.602337853316286, 37.551641669588776],
            [55.60197340477828, 37.55295058758805],
            [55.60174258561209, 37.553658690767996],
          ]}
          options={{
            strokeColor: 'ED9121',
            strokeWidth: 8,
            strokeOpacity: 0.8,
            //strokeStyle: {
            //  style: 'dot',
            //  offset: 10
            //}
          }}
          // replace
          properties={{
            balloonContentBody: `Калужско-Рижская линия`,
            balloonContentFooter: 'Подпись',
          }}
        />

        <Polyline
          geometry={[
            [55.89812270533592, 37.587377790740895],
            [55.89484331317004, 37.58729196005242],
            [55.89345672172592, 37.58729196005242],
            [55.892697089663514, 37.587463621429364],
            [55.89213037030301, 37.58776402883902],
            [55.89149129381874, 37.58825755529778],
            [55.890598980668734, 37.589437727264325],
            [55.88968252949886, 37.59083247595208],
            [55.888597230267756, 37.5931069891967],
            [55.88431603050743, 37.60209775381459],
            [55.883013486918955, 37.60467267446889],
            [55.88190387812257, 37.60664678030385],
            [55.88099928217169, 37.60786986761463],
            [55.87997404783647, 37.60892129354846],
            [55.87899703452139, 37.60967231207265],
            [55.877947622346156, 37.61029458456409],
            [55.876632801366334, 37.610895399383374],
            [55.875173177089486, 37.6113460104979],
            [55.87390651632543, 37.61151767187484],
            [55.873013797145354, 37.61160350256332],
            [55.87228995565269, 37.611582044891186],
            [55.87134894145521, 37.611388925842135],
            [55.870480292640224, 37.61108851843245],
            [55.86969607903443, 37.61068082266219],
            [55.86840510814844, 37.60990834646591],
            [55.867403671151145, 37.60896420889267],
            [55.863735535907246, 37.605337862304545],
            [55.859741216728885, 37.60151839666733],
            [55.85871541866376, 37.60050988607773],
            [55.857194773842274, 37.59909367971787],
            [55.85428607241225, 37.5963470976866],
            [55.85304286776253, 37.59516692572006],
            [55.85059255137324, 37.59287095480331],
            [55.84544218377722, 37.58795579415523],
            [55.84371577596739, 37.58606751900869],
            [55.84260503933422, 37.584715685665195],
            [55.84174781862211, 37.583514056026516],
            [55.840407618628454, 37.581539950191555],
            [55.83895870157977, 37.57935126763542],
            [55.837835753570324, 37.57767756921011],
            [55.83680937475161, 37.57623990517812],
            [55.83591579926681, 37.57508119088371],
            [55.835022203166844, 37.57394393426137],
            [55.83429765064978, 37.57325728875356],
            [55.83352477968852, 37.57263501626212],
            [55.8321842950384, 37.57190545541007],
            [55.83153215059329, 37.57162650567252],
            [55.830384832785676, 37.57143338662344],
            [55.829237480988965, 37.57151921731191],
            [55.826242128740084, 37.57209857445915],
            [55.82469604985371, 37.57254918557364],
            [55.82324654484087, 37.57302125436022],
            [55.82157954703978, 37.57370789986803],
            [55.81971918944355, 37.57471641045763],
            [55.818124526118595, 37.57559617501452],
            [55.8165781232458, 37.57641156655504],
            [55.81359387449276, 37.57804234963611],
            [55.809666881260476, 37.580145201503775],
            [55.80557030124365, 37.582269511043556],
            [55.80334660751731, 37.58334239464952],
            [55.801376598374574, 37.58430798989488],
            [55.79736375481716, 37.58626063805774],
            [55.79494617873454, 37.587397894680045],
            [55.7926251638977, 37.588706812679305],
            [55.789107110966775, 37.59087403756334],
            [55.78728146634369, 37.592247328578964],
            [55.78604820082225, 37.5932772968407],
            [55.784947901430776, 37.5943072651024],
            [55.7835936439543, 37.596002421199806],
            [55.78249327494588, 37.59750445824817],
            [55.781102654080165, 37.599886259853385],
            [55.7793975639645, 37.60342677575305],
            [55.77788589659511, 37.60746081811144],
            [55.77650720461026, 37.612374625026725],
            [55.77527359657198, 37.616709074794755],
            [55.774983330159785, 37.61748155099104],
            [55.77436650682547, 37.618532976924875],
            [55.773725483953164, 37.61932691079329],
            [55.7729151191324, 37.620056471645334],
            [55.77218940493232, 37.6204856250877],
            [55.77145158158523, 37.62085040551376],
            [55.77087098941894, 37.62095769387433],
            [55.770435539595056, 37.62087186318586],
            [55.76993960467908, 37.62065728646468],
            [55.76958881761993, 37.620206675350175],
            [55.76908077556934, 37.619241080104814],
            [55.76883884844634, 37.618382773220034],
            [55.76848805143979, 37.616709074794755],
            [55.76793160830647, 37.613147101222964],
            [55.76771386751787, 37.611859640895815],
            [55.767290479148684, 37.61033614617532],
            [55.766830795126396, 37.60937055092996],
            [55.766250133844295, 37.60855515938944],
            [55.765669463877174, 37.60799725991434],
            [55.76339508954764, 37.60585149270243],
            [55.7625119189228, 37.60514338952249],
            [55.76136255719883, 37.604542574703125],
            [55.76052773630594, 37.60437091332615],
            [55.759438812515825, 37.60424216729344],
            [55.758337758525386, 37.60428508263768],
            [55.757236673315845, 37.60447820168676],
            [55.75622025925889, 37.604778609096414],
            [55.75470768908773, 37.60544379693212],
            [55.75349759054156, 37.60625918847264],
            [55.75222694649099, 37.607332072078606],
            [55.74993968244308, 37.60917743188086],
            [55.748777845954024, 37.610014281093484],
            [55.7469382004468, 37.61138757210913],
            [55.745304231720596, 37.612524828731445],
            [55.74345231742216, 37.61389811974706],
            [55.74203608810546, 37.6149495456809],
            [55.740389807951466, 37.61615117531957],
            [55.738452918482, 37.6175459240073],
            [55.73666120977344, 37.61883338433445],
            [55.735450549019326, 37.61979897957982],
            [55.73241162439594, 37.62196620446385],
            [55.73018373589899, 37.62359698754492],
            [55.72882756734168, 37.62443383675755],
            [55.72778619151715, 37.624862990199944],
            [55.725776015101985, 37.62580712777314],
            [55.72376573475984, 37.62681563836274],
            [55.72240934231559, 37.62739499550995],
            [55.72130723861562, 37.62773831826387],
            [55.720071876462015, 37.627888521968714],
            [55.71907871371938, 37.627802691280216],
            [55.71819453530873, 37.62760957223116],
            [55.71744357347504, 37.62720187646087],
            [55.7167894981281, 37.626686892330035],
            [55.71331302418911, 37.623854479610294],
            [55.71253774024451, 37.62329658013522],
            [55.711944153030494, 37.622996172725536],
            [55.71112038433893, 37.622803053676456],
            [55.71028448357124, 37.62273868066011],
            [55.70903665625446, 37.622652849971644],
            [55.707170898031045, 37.622545561610984],
            [55.705511024933436, 37.622695765315825],
            [55.70392378078047, 37.622888884364905],
            [55.69912530552241, 37.62348969918424],
            [55.69765898673505, 37.623704275905425],
            [55.696338040695586, 37.62383302193813],
            [55.69471406394821, 37.624047598659345],
            [55.691635593722076, 37.624348006069],
            [55.689720516985645, 37.624519667445945],
            [55.68839930154046, 37.624519667445945],
            [55.68698106626223, 37.62447675210171],
            [55.684568735460545, 37.62372573357756],
            [55.68379287872015, 37.623275122463035],
            [55.68283515923909, 37.62228806954557],
            [55.68185316923972, 37.62097915154629],
            [55.680822659274945, 37.61960586053066],
            [55.67993761130999, 37.61844714623625],
            [55.6784341960664, 37.61649449807339],
            [55.67626384106462, 37.6139195774191],
            [55.67553632070606, 37.61308272820644],
            [55.67449351782366, 37.61196692925626],
            [55.67334155196312, 37.611022791683006],
            [55.67215317253784, 37.61027177315886],
            [55.671219420415454, 37.609628042995276],
            [55.67055244091192, 37.60930617791349],
            [55.67001884908791, 37.60917743188078],
            [55.66924270248041, 37.60913451653652],
            [55.66866058237836, 37.609048685848045],
            [55.66739929231534, 37.60894139748741],
            [55.666550324096185, 37.6087053630941],
            [55.665725594400236, 37.60840495568445],
            [55.6649251047674, 37.60795434456995],
            [55.66436717802633, 37.60754664879966],
            [55.663712210355236, 37.60690291863609],
            [55.66300871429911, 37.60600169640709],
            [55.65918778118184, 37.60130246621299],
            [55.657331764215535, 37.599156699001085],
            [55.65660389030287, 37.59868463021446],
            [55.65585173964793, 37.598427138149034],
            [55.65478414605854, 37.59831984978843],
            [55.65354667144996, 37.598341307460565],
            [55.649918946007325, 37.5983842228048],
            [55.647079621072166, 37.59847005349327],
            [55.6460845950319, 37.598770460902905],
            [55.645041003865025, 37.599199614345295],
            [55.64399738477084, 37.59980042916464],
            [55.64324499089003, 37.60070165139363],
            [55.64256539684405, 37.602096400081386],
            [55.641242581619, 37.60499318581747],
            [55.63880314414591, 37.61018594247025],
            [55.63823270641152, 37.6109155033223],
            [55.637492338614805, 37.61158069115798],
            [55.63664271891028, 37.61209567528885],
            [55.63591445872114, 37.61246045571487],
            [55.634749214133954, 37.61291106682934],
            [55.63382670415716, 37.61310418587842],
            [55.63263711961934, 37.613211474239],
            [55.63184808945988, 37.61319001656689],
            [55.63109546120815, 37.61306127053418],
            [55.62991793304473, 37.612546286403315],
            [55.62390834418187, 37.6097138736836],
            [55.61403604140086, 37.60520776253853],
            [55.60546101979418, 37.60113080483584],
            [55.603675332252685, 37.600293955623215],
            [55.60289786455016, 37.600015005885666],
            [55.60187741470508, 37.59975751382022],
            [55.600723302398244, 37.59965022545964],
            [55.6000551165297, 37.59973605614812],
            [55.59941121751537, 37.59990771752506],
            [55.598633664936564, 37.60018666726261],
            [55.59685981519212, 37.60065873604924],
            [55.595669103742964, 37.60085185509827],
            [55.59474566988458, 37.6008947704425],
            [55.59389511939029, 37.6008947704425],
            [55.59314175920429, 37.60074456673766],
            [55.59238838449278, 37.60055144768859],
            [55.591306901854026, 37.600100836574086],
            [55.58907093271851, 37.599178156672934],
            [55.58582611955288, 37.59780486565732],
            [55.58385722518823, 37.5970109317889],
            [55.58276335212926, 37.596581778346504],
            [55.58210701360001, 37.59623845559261],
            [55.58124403320742, 37.59561618310114],
            [55.58049042911323, 37.594950995265464],
            [55.57973681048489, 37.593899569331626],
            [55.57923844180276, 37.593041262446846],
            [55.57853342159666, 37.59153922539851],
            [55.57742724358101, 37.588427862941245],
            [55.576722190712054, 37.58632501107357],
            [55.57606575074934, 37.58447965127132],
            [55.57565243104439, 37.58340676766535],
            [55.574995973120735, 37.582269511043044],
            [55.5743273472392, 37.581454119502524],
            [55.573597924146505, 37.58068164330624],
            [55.57200530310093, 37.57930835229062],
            [55.56959801230858, 37.57731278878352],
          ]}
          options={{
            strokeColor: '99CC00',
            strokeWidth: 8,
            strokeOpacity: 0.8,
            //strokeStyle: {
            //  style: 'dot',
            //  offset: 10
            //}
          }}
          // replace
          properties={{
            balloonContentBody: `Люблинско-Дмитровская линия`,
            balloonContentFooter: 'Подпись',
          }}
        />

        <Polyline
          geometry={[
            [55.92146224476264, 37.546479467681486],
            [55.919968165588806, 37.54598594122275],
            [55.919100609206566, 37.545749906829435],
            [55.918233033325734, 37.545556787780356],
            [55.917353387872936, 37.54544949941978],
            [55.91643757130995, 37.54542804174765],
            [55.915642239300176, 37.54542804174765],
            [55.91453356734191, 37.54557824545249],
            [55.91360563264145, 37.5457284491573],
            [55.911954576792624, 37.54611468725541],
            [55.910640912587304, 37.54641509466509],
            [55.909230782926215, 37.546651129058404],
            [55.90857993648351, 37.546758417418985],
            [55.907712124162664, 37.54686570577959],
            [55.90679607888921, 37.54686570577959],
            [55.90602465548948, 37.54686570577959],
            [55.9054340240275, 37.546801332763245],
            [55.904638465341016, 37.54669404440264],
            [55.903891107404554, 37.5465438406978],
            [55.902733879487, 37.54620051794391],
            [55.89896059347573, 37.54499888830523],
            [55.89769471209143, 37.544548277190735],
            [55.89695927623789, 37.54429078512529],
            [55.89619971303708, 37.54403329305987],
            [55.894837284477816, 37.543625597289555],
            [55.893414520194995, 37.543239359191425],
            [55.89254636709919, 37.54306769781446],
            [55.8916661364072, 37.54287457876538],
            [55.8899056148672, 37.542660002044194],
            [55.888699731912574, 37.542574171355724],
            [55.88644462980354, 37.54268145971633],
            [55.88570897979838, 37.54278874807691],
            [55.88499743614408, 37.542960409453876],
            [55.884092912588926, 37.543239359191425],
            [55.88352606703862, 37.54343247824048],
            [55.8830315779612, 37.54364705496169],
            [55.88262150905006, 37.54384017401074],
            [55.88193403081482, 37.5442264121089],
            [55.88100531273992, 37.544784311583996],
            [55.880209250916415, 37.5452349226985],
            [55.87920811958812, 37.5458142798457],
            [55.878267273751234, 37.546372179320805],
            [55.87776065497041, 37.546608213714116],
            [55.87683183662952, 37.546951536468036],
            [55.875722050386145, 37.54729485922193],
            [55.87292331755176, 37.5479171317134],
            [55.87197025453159, 37.548045877746105],
            [55.87151181078775, 37.548002962401874],
            [55.869424616625395, 37.54727340154982],
            [55.86843527136307, 37.546951536468036],
            [55.86789232723519, 37.54686570577956],
            [55.86734937548506, 37.54688716345167],
            [55.86693914021865, 37.546994451812274],
            [55.866215185016024, 37.54727340154982],
            [55.86481549986618, 37.547809843352795],
            [55.86399497122931, 37.548196081450946],
            [55.86317442518636, 37.54875398092602],
            [55.86272794429196, 37.54916167669631],
            [55.86217285275062, 37.54980540685986],
            [55.86155741586095, 37.55074954443312],
            [55.86010929043866, 37.553195719054706],
            [55.85839560532833, 37.556307081511974],
            [55.856911154753305, 37.559439901641326],
            [55.85625942675628, 37.560963396361785],
            [55.85554734102471, 37.56272292547557],
            [55.85477489422665, 37.56458974294992],
            [55.85412313024619, 37.566113237670386],
            [55.853338584968526, 37.56776547842356],
            [55.85262644548311, 37.56911731176705],
            [55.851552176372486, 37.57083392553659],
            [55.85069515379203, 37.571928266814666],
            [55.8499708945614, 37.572507623961876],
            [55.8492466217766, 37.57291531973216],
            [55.84825676038369, 37.57325864248605],
            [55.84442986297395, 37.574610475829566],
            [55.84393487384672, 37.57471776419015],
            [55.84328292728928, 37.57471776419015],
            [55.84282414350026, 37.57467484884591],
            [55.84217217823622, 37.574524645141096],
            [55.840723327226755, 37.57407403402657],
            [55.8392261242121, 37.57358050756781],
            [55.838248081305586, 37.57338738851874],
            [55.83713718779018, 37.5733444731745],
            [55.8360624887322, 37.57351613455145],
            [55.834939456716626, 37.57396674566594],
            [55.833454106291725, 37.57471776419012],
            [55.83213777252637, 37.57551169805851],
            [55.83100254967804, 37.57632708959906],
            [55.829831061413536, 37.577335600188654],
            [55.82893732489837, 37.57832265310613],
            [55.82782616425609, 37.57993197851506],
            [55.82394893018717, 37.5857899230036],
            [55.82179877018698, 37.588965658477214],
            [55.819346475771134, 37.59282803945866],
            [55.817171897682854, 37.59626126699772],
            [55.81397021338932, 37.601518396666926],
            [55.81104618004177, 37.60649657659857],
            [55.809402824801595, 37.609286073974054],
            [55.8082065150325, 37.611260179809015],
            [55.806949745810556, 37.613277200988215],
            [55.80572919053902, 37.615101103118306],
            [55.80467779034214, 37.616109613707906],
            [55.8036263616292, 37.616688970855115],
            [55.80235735796435, 37.617118124297505],
            [55.80177722816864, 37.61728978567445],
            [55.80083449873645, 37.61733270101869],
            [55.79951705638151, 37.61733270101869],
            [55.79823583098044, 37.61718249731385],
            [55.796857862074084, 37.616925005248426],
            [55.79563698906287, 37.616624597838744],
            [55.7937632973024, 37.61617398672425],
            [55.79211920932985, 37.61585212164246],
            [55.79064430640422, 37.61550879888857],
            [55.78954413736797, 37.6152513068231],
            [55.78812958847683, 37.61503673010189],
            [55.78679962366783, 37.61484361105284],
            [55.78508269258022, 37.614693407348],
            [55.78400654914592, 37.61460757665952],
            [55.78296665185075, 37.61458611898739],
            [55.781455123518576, 37.61469340734795],
            [55.78029422978572, 37.61488652639702],
            [55.77891562336545, 37.61531567983941],
            [55.777198343356496, 37.615852121642384],
            [55.775650307516656, 37.61649585180596],
            [55.7742473466974, 37.61713958196954],
            [55.772808049706974, 37.61786914282158],
            [55.76951802757595, 37.62007928304986],
            [55.767957588708576, 37.6217958968194],
            [55.76737694428025, 37.622825865081104],
            [55.76706242492199, 37.623705629637996],
            [55.766614835288046, 37.625615362456585],
            [55.76632450411756, 37.6275250952752],
            [55.766022073506115, 37.630314592650684],
            [55.76582851667837, 37.63303971700983],
            [55.76565915366248, 37.63658023290949],
            [55.765465595025354, 37.639691595366756],
            [55.76534462038711, 37.64091468267754],
            [55.76503008456348, 37.64267421179132],
            [55.76461876464142, 37.64419770651178],
            [55.7640501681619, 37.6459143202813],
            [55.76352995557782, 37.64718032293634],
            [55.762707279583836, 37.64861798696828],
            [55.761521627644406, 37.65027022772146],
            [55.76036013757896, 37.651450399688],
            [55.759622089381274, 37.65198684149097],
            [55.75835164569446, 37.65293097906422],
            [55.7579160554337, 37.65329575949025],
            [55.75726266088147, 37.65385365896535],
            [55.756343049924745, 37.655441526702155],
            [55.756028441177286, 37.656450037291755],
            [55.755907437134276, 37.6571581404717],
            [55.755750131314706, 37.658467058470954],
            [55.75558072433495, 37.65984034948658],
            [55.75538711545323, 37.66108489446949],
            [55.75501199549209, 37.662694219878425],
            [55.754540065241365, 37.664239172271024],
            [55.75414073516563, 37.66537642889333],
            [55.75381400750436, 37.666063074401144],
            [55.753305759012676, 37.66717887335135],
            [55.75213192160285, 37.669560674956564],
            [55.75141792067278, 37.67101979666066],
            [55.75037715004629, 37.67314410620046],
            [55.749820447334066, 37.674281362822775],
            [55.749166916578716, 37.67563319616627],
            [55.74754514461879, 37.67893767767261],
            [55.74684316262066, 37.6803538840325],
            [55.74639533989559, 37.681040529540304],
            [55.74586278723306, 37.68159842901538],
            [55.745293916098206, 37.68187737875294],
            [55.74468872489598, 37.68207049780201],
            [55.74407142015827, 37.68213487081838],
            [55.74350252279445, 37.68207049780201],
            [55.74292151263404, 37.681855921080825],
            [55.742449435481575, 37.68159842901538],
            [55.74167473234119, 37.68108344488454],
            [55.74116632501139, 37.68054700308155],
            [55.74070633169554, 37.679903272917976],
            [55.740282648824135, 37.67921662741016],
            [55.73993159380268, 37.678315405181166],
            [55.739592641117596, 37.67741418295217],
            [55.73905999521063, 37.67535424642871],
            [55.737752560662095, 37.670569185546164],
            [55.737510438327334, 37.66953921728444],
            [55.73712303945416, 37.6684877913506],
            [55.736505614518485, 37.666878465941664],
            [55.735827646428056, 37.66593432836843],
            [55.73529494891756, 37.66541934423756],
            [55.73483488610117, 37.665140394500014],
            [55.73395106593789, 37.66492581777883],
            [55.73301879549341, 37.66496873312307],
            [55.732231796538485, 37.66511893682789],
            [55.73139634945025, 37.6654622595818],
            [55.73068196740934, 37.6659128706963],
            [55.729894921148386, 37.66653514318777],
            [55.728793029613, 37.667500738433134],
            [55.72403395251463, 37.67155623846361],
            [55.721018357915156, 37.6740238707573],
            [55.718026753909946, 37.676341299346156],
            [55.714780542691074, 37.67829394750901],
            [55.7130725392112, 37.67945266180345],
            [55.71004397986412, 37.68198466711349],
            [55.70823884635676, 37.68361545019455],
            [55.70616707985013, 37.68550372534104],
            [55.705791484635945, 37.68597579412767],
            [55.70551281487608, 37.68655515127488],
            [55.70500393450633, 37.688014272978975],
            [55.70457986245285, 37.68985963278123],
            [55.703307618561944, 37.695352796843714],
            [55.70267753951976, 37.69792771749802],
            [55.70220497354306, 37.699537042906954],
            [55.700702417948904, 37.70436501913376],
            [55.696715517499655, 37.71676755361858],
            [55.69292212976136, 37.72865510397258],
            [55.692619127577665, 37.72981381826702],
            [55.69243732513542, 37.7310154479057],
            [55.692400964545094, 37.73225999288861],
            [55.69242520494242, 37.73393369131389],
            [55.692497926037625, 37.73951268606483],
            [55.69254640669646, 37.742280725768204],
            [55.6924252049362, 37.74500585012734],
            [55.6920979582987, 37.74753785543741],
            [55.69134649264591, 37.75127149038612],
            [55.69057077095068, 37.75446868353189],
            [55.68915261476985, 37.75989747457801],
            [55.688582913555294, 37.761549715331185],
            [55.68783138005286, 37.762944464018936],
            [55.687213172265594, 37.76363110952675],
            [55.686279781120675, 37.764339212706645],
            [55.68518877610481, 37.76481128149327],
            [55.68429170463639, 37.76494002752598],
            [55.683624948940796, 37.76491856985385],
            [55.682897566070935, 37.7647254508048],
            [55.680097015162545, 37.76365256719878],
            [55.67705376402563, 37.762450937560125],
            [55.673161450920915, 37.76090598516751],
            [55.67095442150387, 37.760047678282724],
            [55.670141912155685, 37.75972581320094],
            [55.669208111828766, 37.75948977880763],
            [55.66805598967684, 37.75933957510281],
            [55.66565461505825, 37.75906062536521],
            [55.66486625274295, 37.758803133299786],
            [55.66427193830028, 37.75854564123434],
            [55.66348354801246, 37.7579233687429],
            [55.66276791840371, 37.75717235021872],
            [55.66222209037695, 37.75633550100609],
            [55.66139726907087, 37.75491929464621],
            [55.66063308081787, 37.75328851156517],
            [55.660147874150226, 37.752022508910144],
            [55.658134201930636, 37.74687266760155],
            [55.657588309008204, 37.74567103796287],
            [55.656933227409795, 37.744619612029034],
            [55.656217477516456, 37.74382567816062],
            [55.65518628931923, 37.74305320196434],
            [55.65403375258327, 37.74264550619407],
            [55.652844784245225, 37.74260259084984],
            [55.65171644402418, 37.74286008291526],
            [55.65056380470552, 37.74326777868552],
            [55.64823415594216, 37.744061712553886],
            [55.64287058131103, 37.74571395330706],
            [55.6409652426244, 37.746207479765815],
            [55.64017638089357, 37.74637914114276],
            [55.63927827273254, 37.746443514159104],
            [55.63856219870676, 37.746422056487],
            [55.6376640533791, 37.74633622579853],
            [55.636923674789465, 37.746121649077345],
            [55.63606190496415, 37.74571395330706],
            [55.63495735523893, 37.744962934782905],
            [55.633476481257816, 37.7437398474721],
            [55.63252966352121, 37.742988828947944],
            [55.631825604668066, 37.742559675505554],
            [55.631085115270146, 37.742323641112236],
            [55.630259635114214, 37.74234509878437],
            [55.62840224091087, 37.74264550619402],
            [55.62389799633211, 37.7432892363576],
            [55.62081392800359, 37.743804220488464],
            [55.6185796475567, 37.744147543242384],
            [55.61490009180972, 37.74479127340593],
            [55.61233755606748, 37.74517751150409],
          ]}
          options={{
            strokeColor: '999999',
            strokeWidth: 8,
            strokeOpacity: 0.8,
            //strokeStyle: {
            //  style: 'dot',
            //  offset: 10
            //}
          }}
          // replace
          properties={{
            balloonContentBody: `Серпуховско-Тимирязевская линия`,
            balloonContentFooter: 'Подпись',
          }}
        />

        <Polyline
          geometry={[
            [55.74831367958174, 37.53427005224592],
            [55.74843470728338, 37.53555751257307],
            [55.748555734607976, 37.53652310781843],
            [55.748797788126, 37.53725266867048],
            [55.75043160992436, 37.542252306274264],
            [55.750637345575754, 37.54296040945418],
            [55.750746264008875, 37.54409766607649],
            [55.75072205993902, 37.545470957092114],
            [55.750625243508765, 37.546350721649006],
            [55.750383201377446, 37.547251943878024],
            [55.74991121488355, 37.54856086187728],
            [55.74948763237811, 37.5494835417784],
            [55.74884619864863, 37.55079245977768],
            [55.74707917548152, 37.55394673757919],
            [55.74617142685589, 37.555534605316],
            [55.745275760757856, 37.557294134429775],
            [55.74423482556659, 37.55963302069076],
            [55.74369013906161, 37.56107068472275],
            [55.74348436661701, 37.56165004186995],
            [55.743387532148354, 37.56220794134506],
            [55.74330280179031, 37.56308770590195],
            [55.743351219160395, 37.564160589507885],
            [55.74360540936347, 37.56568408422835],
          ]}
          options={{
            strokeColor: '00BFFF',
            strokeWidth: 8,
            strokeOpacity: 0.8,
            //strokeStyle: {
            //  style: 'dot',
            //  offset: 10
            //}
          }}
          // replace
          properties={{
            balloonContentBody: `Филёвская линия`,
            balloonContentFooter: 'Подпись',
          }}
        />

        <Polyline
          geometry={[
            [55.73089848155828, 37.44657910432496],
            [55.731213293847624, 37.448510294815684],
            [55.73150388754416, 37.44954026307742],
            [55.73179447906836, 37.4503127392737],
            [55.73348955299768, 37.45550549592654],
            [55.73380434430318, 37.45657837953248],
            [55.735087697857246, 37.4620930012671],
            [55.73546301022807, 37.46380961503664],
            [55.73620151752457, 37.46833718385377],
            [55.7365889255834, 37.47026837434449],
            [55.737133711632644, 37.472778921982425],
            [55.73763006560683, 37.475096350571306],
            [55.7380295654206, 37.476920252701426],
            [55.738925398552, 37.48112595643679],
            [55.739603312605375, 37.483636504074724],
            [55.740717002877496, 37.48734868135133],
            [55.74168540325722, 37.49063170518557],
            [55.7441062986169, 37.498871451279285],
            [55.745449830460004, 37.50346339311278],
            [55.745861353441555, 37.5042787846533],
            [55.746188147998275, 37.50468648042356],
            [55.747713152899316, 37.5061241444555],
            [55.74828198861166, 37.50687516297968],
            [55.748584557405636, 37.507647639175964],
            [55.74879030283947, 37.508420115372246],
            [55.74887502123077, 37.50936425294548],
            [55.74885081599496, 37.5102654751745],
            [55.74869348159456, 37.51118815507562],
            [55.74841511917135, 37.51185334291131],
            [55.74803993187977, 37.51256144609125],
            [55.747543710864356, 37.51314080323846],
            [55.74526827479894, 37.51582301225336],
            [55.74474338348756, 37.51655862030842],
            [55.744416576778484, 37.51750275788166],
            [55.744011519151954, 37.51902765431679],
            [55.74375733160566, 37.52020782628333],
            [55.74367260205402, 37.52078718343057],
            [55.74366049781727, 37.52155965962685],
            [55.74379364421408, 37.52243942418374],
            [55.743987310885856, 37.52381271519936],
            [55.74408414385975, 37.52456373372352],
            [55.743963102604674, 37.52572244801796],
            [55.743781540015036, 37.52664512791908],
            [55.743454725215685, 37.52866214909828],
            [55.74332157765749, 37.53014272847451],
            [55.74321263840687, 37.531494561818],
            [55.74295844563429, 37.53241724171912],
            [55.7427284602643, 37.532910768177885],
            [55.74237742733825, 37.53333992162025],
            [55.74213533381741, 37.53351158299719],
            [55.74125167966621, 37.533876363423246],
            [55.74039221593477, 37.5341767708329],
            [55.739532733200356, 37.53467029729163],
            [55.73901219216081, 37.53514236607826],
            [55.73847953829801, 37.535743180897605],
            [55.73788634699485, 37.53649419942175],
            [55.73758369488257, 37.536944810536276],
            [55.73737789009994, 37.537459794667114],
            [55.73709944660051, 37.538575593617324],
            [55.7369420646097, 37.53960556187905],
            [55.73689363925352, 37.54080719151773],
            [55.73707523402801, 37.5419015327958],
            [55.73747473954516, 37.54340356984413],
            [55.73858849081215, 37.547523442891],
            [55.73907272054065, 37.54936880269325],
            [55.73936325548161, 37.55057043233193],
            [55.74038011066358, 37.55445427098548],
            [55.74123957465649, 37.55756563344278],
            [55.74241374121747, 37.56166404881751],
            [55.743624185065606, 37.56571954884804],
            [55.744314121180494, 37.56795114674839],
            [55.744592512988376, 37.56825155415805],
            [55.745028252683205, 37.568551961567735],
            [55.74686798863067, 37.569560472157335],
            [55.74741263058612, 37.570032540943934],
            [55.74776361802271, 37.57069772877964],
            [55.74800567647626, 37.5715560356644],
            [55.74809039657868, 37.57232851186068],
            [55.748138807982826, 37.57340139546665],
            [55.74834455577742, 37.57792896428378],
            [55.74841717238594, 37.579066220906114],
            [55.74858661060501, 37.58063263097076],
            [55.7489617926169, 37.58359378972319],
            [55.74937327839907, 37.58606142201691],
            [55.74973635046956, 37.58792823949127],
            [55.7500026011646, 37.58917278447418],
            [55.75069242402171, 37.59166187444001],
            [55.75114019721438, 37.59346431889799],
            [55.75149115096709, 37.59513801732325],
            [55.751696881006794, 37.596747342732186],
            [55.75189050828409, 37.598614160206566],
            [55.75198732156078, 37.60018057027127],
            [55.75219304897256, 37.60301298299099],
            [55.7522898614952, 37.606017057087655],
            [55.752338267666026, 37.60925716557766],
          ]}
          options={{
            strokeColor: '00BFFF',
            strokeWidth: 8,
            strokeOpacity: 0.8,
            //strokeStyle: {
            //  style: 'dot',
            //  offset: 10
            //}
          }}
          // replace
          properties={{
            balloonContentBody: `Филёвская линия`,
            balloonContentFooter: 'Подпись',
          }}
        />

        <Polyline
          geometry={[
            [55.60710265986728, 37.287667755416784],
            [55.607843604342264, 37.28642321043387],
            [55.608341608302766, 37.285736564926054],
            [55.60910682152406, 37.285071377090375],
            [55.610066353420834, 37.284685138992224],
            [55.610952988517816, 37.28457785063164],
            [55.612276830848145, 37.28462076597588],
            [55.615580168257324, 37.284685138992224],
            [55.61882251072602, 37.284835342697065],
            [55.61972108981947, 37.285221580795216],
            [55.62031608351182, 37.285843853286664],
            [55.62085035583371, 37.28659487181084],
            [55.62263525806385, 37.28944874220269],
            [55.62301165559581, 37.290307049087446],
            [55.62327877422285, 37.2911438983001],
            [55.62358231589814, 37.29221678190604],
            [55.624942153619244, 37.297409538558874],
            [55.62584059183803, 37.30125046186822],
            [55.62649619586321, 37.304876808456356],
            [55.62724891282248, 37.308653358749304],
            [55.628511502226445, 37.31309509687799],
            [55.62985902854195, 37.31607771330254],
            [55.63046600717604, 37.3173866313018],
            [55.63085446854674, 37.3183951418914],
            [55.63135217902604, 37.3199615519561],
            [55.63172849249067, 37.321485046676564],
            [55.632250469674815, 37.32448912077323],
            [55.63346434309987, 37.3317418139495],
            [55.634398999895275, 37.33740663938896],
            [55.63454465867883, 37.33976698332207],
            [55.634423276396966, 37.342620853713896],
            [55.63435044684659, 37.34493828230278],
            [55.63450824402778, 37.34616136961356],
            [55.63477528396776, 37.34695530348198],
            [55.63552784123723, 37.348435882858205],
            [55.63971519316641, 37.35540962629692],
            [55.646559635277235, 37.36676073484795],
            [55.64704501108947, 37.36787653379813],
            [55.647712392966774, 37.37021542005912],
            [55.648622440755794, 37.37371302061454],
            [55.64874377885012, 37.374807361892586],
            [55.648792313984586, 37.375901703170655],
            [55.6487559126394, 37.37699604444873],
            [55.64852536999741, 37.37858391218556],
            [55.648246290238625, 37.37967825346363],
            [55.647627454083306, 37.38141632490528],
            [55.6469358020523, 37.38336897306811],
            [55.64677805513421, 37.38429165296924],
            [55.6467052486491, 37.38568640165699],
            [55.64681445832579, 37.387102608016846],
            [55.64708141402568, 37.38819694929492],
            [55.64748184414889, 37.38907671385181],
            [55.64857390540373, 37.39019251280201],
            [55.654980048742736, 37.39626503401171],
            [55.656108294511256, 37.39778852873217],
            [55.656690602131874, 37.398968700698745],
            [55.65724863877259, 37.400642399124024],
            [55.65827977240937, 37.404869560531495],
            [55.659141051368195, 37.40841007643116],
            [55.659407922676436, 37.410062317184334],
            [55.659529227212516, 37.4116072695769],
            [55.65955348807442, 37.41377449446094],
            [55.65954135764537, 37.41695022993458],
            [55.65963840096603, 37.427936558059585],
            [55.659699052922605, 37.42911673002613],
            [55.65992952949687, 37.43079042845141],
            [55.6601721349448, 37.431777481368904],
            [55.66070586160861, 37.43347263746631],
            [55.66132449019437, 37.43476009779346],
            [55.66178542275684, 37.435618404678216],
            [55.6625738473976, 37.43669128828419],
            [55.663022635838765, 37.43729210310352],
            [55.66531501509088, 37.44012451582324],
            [55.66791045632722, 37.44319296293627],
            [55.66852897055422, 37.44396543913256],
            [55.66900194539723, 37.444694999984634],
            [55.669426405111636, 37.44570351057423],
            [55.669875114638955, 37.446862224868646],
            [55.67039658138096, 37.44838571958911],
            [55.670893787355915, 37.45001650267018],
            [55.672421746493136, 37.45486593656909],
            [55.67354948741194, 37.457569603256104],
            [55.6741072826841, 37.45864248686207],
            [55.67484695540098, 37.45984411650075],
            [55.67557448863447, 37.46085262709035],
            [55.67951505751798, 37.466367248824966],
            [55.68238839024009, 37.470487121871784],
            [55.68350371966793, 37.47233248167404],
            [55.6855039587334, 37.47580862455733],
            [55.68797683953174, 37.480293278030246],
            [55.69264336807623, 37.48898363523848],
            [55.69460677912388, 37.49314642362956],
            [55.6955157321869, 37.49477720671063],
            [55.69867872339536, 37.50050640516643],
            [55.70316221682991, 37.50851011686683],
            [55.70531895668684, 37.512372497848276],
            [55.70654267164462, 37.51486158781405],
            [55.70900210115151, 37.5207624476468],
            [55.70954727383326, 37.521728042892164],
            [55.71005609477081, 37.52247906141635],
            [55.71074662683603, 37.52312279157992],
            [55.711291775071686, 37.52340174131747],
            [55.711861143961734, 37.52348757200595],
            [55.71245473244238, 37.523466114333836],
            [55.71323001804135, 37.52310133390779],
            [55.71407796897279, 37.52232885771151],
            [55.715119711955175, 37.52110577040072],
            [55.71616142703782, 37.51958227568026],
            [55.7171062142647, 37.5180587809598],
            [55.7182932220893, 37.515913013747834],
            [55.718995719862896, 37.51396036558501],
            [55.719492305461216, 37.512029175094284],
            [55.719831433445535, 37.51031256132474],
            [55.720073665909496, 37.50855303221099],
            [55.72053390343423, 37.5055489581143],
            [55.7212484719492, 37.50230884962432],
            [55.72189036129004, 37.500356201461464],
            [55.722641237260774, 37.49881124906889],
            [55.72397340088182, 37.497523788741745],
            [55.725693035525815, 37.496322159103066],
            [55.72702509458746, 37.49567842893949],
            [55.72786063562005, 37.495463852218315],
            [55.729192620424755, 37.49544239454618],
            [55.730197633296605, 37.49567842893949],
            [55.731117863259485, 37.496129040053994],
            [55.732122826353276, 37.49703026228301],
            [55.73306722582711, 37.49831772261016],
            [55.73384210056618, 37.49975538664213],
            [55.734507996203554, 37.50145054273956],
            [55.73507702525401, 37.50331736021392],
            [55.73560972575127, 37.505742077163376],
            [55.736093992588756, 37.50962591581693],
            [55.73620295179705, 37.51192188673368],
            [55.736178738666034, 37.51479721479766],
            [55.73619084523342, 37.519045833877236],
            [55.73623927146526, 37.52247906141629],
            [55.736396656302006, 37.52387381010405],
            [55.73675984964675, 37.52531147413604],
            [55.73734095193878, 37.52689934187284],
            [55.73804310560977, 37.52810097151147],
            [55.7389994669355, 37.528937820724124],
            [55.739713696118145, 37.52921677046167],
            [55.7412631651464, 37.52904510908473],
            [55.74448295786431, 37.52887344770773],
            [55.74517287873639, 37.52904510908468],
            [55.74594751213308, 37.529796127608854],
            [55.746540580382145, 37.5309762995754],
            [55.747109433263454, 37.53271437101705],
            [55.74856178581773, 37.537306312850546],
          ]}
          options={{
            strokeColor: 'FFD702',
            strokeWidth: 8,
            strokeOpacity: 0.8,
            //strokeStyle: {
            //  style: 'dot',
            //  offset: 10
            //}
          }}
          // replace
          properties={{
            balloonContentBody: `Солнцевская линия`,
            balloonContentFooter: 'Подпись',
          }}
        />

        <Polyline
          geometry={[
            [55.74506999674824, 37.863774065307496],
            [55.7442348256042, 37.85866713934313],
            [55.7440532642843, 37.85733676367175],
            [55.74398063951877, 37.85624242239368],
            [55.74393222293303, 37.85519099645984],
            [55.74393222293303, 37.853581671050904],
            [55.7440290560442, 37.85195088796986],
            [55.744198513408094, 37.850277189544556],
            [55.744416386075684, 37.84877515249622],
            [55.7446584653846, 37.84753060751331],
            [55.74497316623129, 37.84607148580922],
            [55.74528786452903, 37.844805483154175],
            [55.74569938922612, 37.843346361450074],
            [55.74626825442409, 37.841436628631484],
            [55.74699445321147, 37.839376692108026],
            [55.7475875054789, 37.837638620666375],
            [55.74804741733598, 37.83624387197865],
            [55.7484105017983, 37.83497786932361],
            [55.748761480219514, 37.833840612701295],
            [55.749160865600274, 37.83242440634144],
            [55.749584451668774, 37.83098674230945],
            [55.749911214908614, 37.82954907827746],
            [55.75061314145682, 37.82637334280385],
            [55.75081887614673, 37.82530045919788],
            [55.75103671168959, 37.824098829559205],
            [55.75118193470615, 37.823090318969605],
            [55.751302953471885, 37.82191014700306],
            [55.75141187003869, 37.820729975036514],
            [55.75154498987225, 37.818948988250604],
            [55.751714414448905, 37.81620240621936],
            [55.75178702475822, 37.814292673400764],
            [55.75179912646325, 37.81264043264759],
            [55.75182332986198, 37.810494665435684],
            [55.75184753324564, 37.806610826782105],
            [55.75183543155569, 37.80476546697985],
            [55.75178702475822, 37.80332780294789],
            [55.751726516176554, 37.80173993521106],
            [55.751387666371016, 37.793736223510585],
            [55.751302953459344, 37.79154754095444],
            [55.75129085159973, 37.7898309271849],
            [55.751302953459344, 37.78839326315291],
            [55.75133925901552, 37.78689122610458],
            [55.751387666371016, 37.785753969482265],
            [55.751484480901006, 37.78384423666365],
            [55.75166600749435, 37.781870130828686],
            [55.7520048548655, 37.779273752502284],
            [55.752428409922686, 37.7769563239134],
            [55.75294877123533, 37.774703268340865],
            [55.753541732612234, 37.77247167044049],
            [55.754098381993444, 37.77069068365458],
            [55.75459451928296, 37.76925301962261],
            [55.75530846182152, 37.767064337066444],
            [55.75615549526887, 37.76453233175638],
            [55.75673630752102, 37.76262259893776],
            [55.75713561091002, 37.761013273528825],
            [55.75745021068851, 37.75936103277565],
            [55.75760750962206, 37.75811648779274],
            [55.75771640851051, 37.756743196777116],
            [55.75777690776105, 37.75545573644997],
            [55.757752708072154, 37.75401807241798],
            [55.75765590914692, 37.75129294805876],
            [55.7575107103349, 37.74747348242155],
            [55.75734131103475, 37.744683985046066],
            [55.75705091051538, 37.74303174429289],
            [55.75636120057243, 37.7395556014096],
            [55.7556472773859, 37.73575759344452],
            [55.75503014676814, 37.732624773315116],
            [55.75401367495585, 37.727432016662284],
            [55.75326340489228, 37.7236340086972],
            [55.752525221814736, 37.71972871237151],
            [55.75103671162679, 37.712046865752804],
            [55.74968127062395, 37.70503020696985],
            [55.74895512205365, 37.701339487365374],
            [55.74861624807858, 37.699472669890994],
            [55.74838629619767, 37.697670225433],
            [55.748132137272016, 37.695009474090206],
            [55.747817462012655, 37.69086814337123],
            [55.747369650511985, 37.685439352325034],
            [55.74701865951604, 37.680740122130956],
            [55.74692183385567, 37.67966723852499],
            [55.74678869817864, 37.679002050689306],
            [55.7465708388139, 37.677671675017926],
            [55.745372590464115, 37.67140603475912],
            [55.74431955383564, 37.667007211974706],
            [55.74362961781096, 37.66466832571372],
            [55.74298808735766, 37.66260838919028],
            [55.742019719436726, 37.6591751616512],
            [55.74151131662066, 37.657437090209555],
            [55.741329742574294, 37.6565787833248],
            [55.741269217703646, 37.65574193411214],
            [55.741305532637355, 37.65434718542441],
            [55.74140237229466, 37.65308118276937],
            [55.74157184111423, 37.651386026671965],
            [55.74166868010786, 37.650077108672704],
            [55.74174130919471, 37.64818883352622],
            [55.74180183332379, 37.64597869329794],
            [55.74174130918845, 37.643596891692724],
            [55.74165657524059, 37.641579870513524],
            [55.74149921170435, 37.63975596838338],
            [55.74134184753085, 37.637223963073346],
            [55.74123290272973, 37.63531423025472],
            [55.741123957623145, 37.63340449743613],
            [55.74103922232909, 37.631795172027196],
            [55.74094238175427, 37.626452211669516],
          ]}
          options={{
            strokeColor: 'FFD702',
            strokeWidth: 8,
            strokeOpacity: 0.8,
            //strokeStyle: {
            //  style: 'dot',
            //  offset: 10
            //}
          }}
          // replace
          properties={{
            balloonContentBody: `Калининская линия`,
            balloonContentFooter: 'Подпись',
          }}
        />

        <Polyline
          geometry={[
            [55.8607730214551, 37.43645525389082],
            [55.85693529275845, 37.437678341201625],
            [55.85403864166105, 37.4386653941191],
            [55.85052616132592, 37.43954515867598],
            [55.849475976433645, 37.43973827772506],
            [55.84712201021638, 37.43986702375775],
            [55.845287024438505, 37.43975973539714],
            [55.84237742787995, 37.43937349729896],
            [55.83754773924232, 37.43875122480747],
            [55.83219815592403, 37.43808603697176],
            [55.82992768048413, 37.43774271421782],
            [55.82797110020281, 37.43731356077542],
            [55.82642509031997, 37.436948780349375],
            [55.81952768523873, 37.43548965864528],
            [55.81794509511473, 37.435210708907675],
            [55.81720814178047, 37.43514633589134],
            [55.816688641663255, 37.435210708907675],
            [55.81596374613922, 37.43551111631736],
            [55.815275082830745, 37.435940269759726],
            [55.81496095163674, 37.43624067716936],
            [55.81455016085677, 37.43688440733293],
            [55.81409103659653, 37.43776417188982],
            [55.813740648633065, 37.43868685179095],
            [55.81337817499224, 37.43988848142962],
            [55.81308819363855, 37.4410042803798],
            [55.81273779660788, 37.442999843886895],
            [55.81197657834208, 37.44829988890031],
            [55.811142845943884, 37.454093460372476],
            [55.81068368130953, 37.457355026534586],
            [55.81040576323412, 37.45900726728776],
            [55.8101278431659, 37.460294727614915],
            [55.80988617192146, 37.461153034499695],
            [55.809656582843495, 37.46186113767961],
            [55.80926990342127, 37.46291256361342],
            [55.80873821292559, 37.46394253187513],
            [55.80757813561259, 37.466281418136084],
            [55.80524579178864, 37.47076607160898],
            [55.802864964506036, 37.47544384413095],
            [55.799928006596346, 37.481044296554046],
            [55.79770398921976, 37.48537874632212],
            [55.79552819661194, 37.4895629923853],
            [55.79350943540577, 37.493511204055224],
            [55.79261486091502, 37.49527073316901],
            [55.792082941976325, 37.49623632841437],
            [55.7913696755862, 37.497459415725146],
            [55.790886097691214, 37.4981889765772],
            [55.78999146268471, 37.49941206388798],
            [55.789374877903015, 37.500098709395814],
            [55.78856483985598, 37.50093555860844],
            [55.78692053151603, 37.502223018935595],
            [55.78561470758691, 37.503274444869426],
            [55.784695768106694, 37.50400400572148],
            [55.783994457543, 37.50454044752447],
            [55.783135939415935, 37.50522709303228],
            [55.78249506139277, 37.50576353483525],
            [55.78157604801127, 37.50651455335938],
            [55.78060864199422, 37.507437233260504],
            [55.7799677222249, 37.50814533644045],
            [55.779568653618064, 37.50868177824337],
            [55.779133301372944, 37.50941133909544],
            [55.77890353016326, 37.5098190348657],
            [55.77841979685348, 37.51112795286496],
            [55.77803280585757, 37.51299477033931],
            [55.77764581100228, 37.51632070951779],
            [55.777524874320505, 37.5175652545007],
            [55.777440218393764, 37.51962519102406],
            [55.7772588122813, 37.522479061415915],
            [55.77695646685503, 37.5259337466271],
            [55.776448521235956, 37.53071880750965],
            [55.77550517601237, 37.53627634458848],
            [55.774622280869295, 37.54099703245469],
            [55.77371517589969, 37.54599667005844],
            [55.77306204718737, 37.54949427061382],
            [55.77280804972236, 37.550481323531315],
            [55.772517764869846, 37.551532749465146],
            [55.77225166851448, 37.55221939497296],
            [55.77193718865161, 37.55294895582501],
            [55.77162270624073, 37.553549770644345],
            [55.77116307351199, 37.554279331496346],
            [55.77064295623559, 37.55494451933205],
            [55.768731767779045, 37.55698299818338],
            [55.766687417750504, 37.55930042677223],
            [55.76541720521807, 37.56067371778786],
            [55.76475183921348, 37.56136036329566],
            [55.76398967868744, 37.56226158552469],
            [55.76339687682322, 37.563119892409446],
            [55.76307022703033, 37.56376362257302],
            [55.762719377895486, 37.564600471785674],
            [55.76238062399213, 37.5655446093589],
            [55.761993473062226, 37.567003731063],
            [55.7615700223063, 37.56912804060275],
            [55.76136434455534, 37.570351127913554],
            [55.76119496206524, 37.57178879194552],
            [55.76091668922745, 37.57464266233737],
            [55.76074125529499, 37.576895717909856],
            [55.76072915636977, 37.57921314649871],
            [55.76083804649816, 37.581552032759696],
            [55.76108002347574, 37.58391237669281],
            [55.76143088742389, 37.58592939787201],
            [55.76185433968456, 37.58775330000213],
            [55.762713328624926, 37.59065008573819],
            [55.76370840124169, 37.59395456724441],
            [55.764095535072144, 37.59526348524366],
            [55.764797205301086, 37.59779549055373],
            [55.76503915761211, 37.598675255110614],
            [55.76560773960781, 37.60193682127273],
            [55.7657166160613, 37.603095535567164],
            [55.76577710284798, 37.604061130812525],
            [55.76574081078729, 37.60509109907423],
            [55.7657166160613, 37.60640001707351],
            [55.76558354479891, 37.60822391920363],
            [55.76537788831439, 37.61034822874343],
            [55.76494237686235, 37.613094810774626],
            [55.76456734920265, 37.61513328962596],
            [55.76384147892502, 37.618287567427465],
            [55.76329706731096, 37.62021875791819],
            [55.76265586162002, 37.62214994840891],
            [55.76189365994232, 37.623802189162085],
            [55.761421813314335, 37.62466049604684],
            [55.75988524737781, 37.627171043684804],
            [55.75781623182903, 37.62993908338815],
            [55.75565030213634, 37.63242817335395],
            [55.754282921196676, 37.63395166807439],
            [55.750470941411066, 37.63867235594062],
            [55.74852245171174, 37.641204361250686],
            [55.74831670485931, 37.64152622633247],
            [55.746973272154754, 37.64319992475775],
            [55.74530299373344, 37.645474438002395],
            [55.74333003614251, 37.64804935865667],
            [55.74186538320134, 37.6500234644916],
            [55.740509622185556, 37.651804451277435],
            [55.73955329802383, 37.65302753858824],
            [55.73801586680908, 37.654443744948104],
            [55.73580040620542, 37.65624618940612],
            [55.73426282652192, 37.657469276716895],
            [55.733718000230574, 37.657962803175636],
            [55.7333063486345, 37.65837049894589],
            [55.73283415467056, 37.65907860212584],
            [55.73253146321506, 37.65997982435483],
            [55.732265092784466, 37.66100979261656],
            [55.73202293626356, 37.662232879927345],
            [55.73180499410494, 37.66349888258239],
            [55.73168391459995, 37.664872173598006],
            [55.73168391459995, 37.66628837995787],
            [55.73162337469975, 37.66764021330133],
            [55.73156586169443, 37.66853070669428],
            [55.731481105559126, 37.66960359030024],
            [55.731323700817576, 37.67080521993892],
            [55.731117862886144, 37.671921018889094],
            [55.73091202386476, 37.67256474905267],
            [55.73030660866123, 37.673895124724055],
            [55.729422685532015, 37.67565465383783],
            [55.72840554363102, 37.67771459036122],
            [55.72764266974573, 37.679517034819234],
            [55.72667825141252, 37.68212110233889],
            [55.72560048843405, 37.685318295484606],
            [55.72448636496958, 37.68853694630248],
            [55.72308155509228, 37.69278556538203],
            [55.721398138480026, 37.697892491346394],
            [55.72093791118334, 37.69907266331294],
            [55.71832177885296, 37.70430833531001],
            [55.716650268846124, 37.70737678242304],
            [55.715584340890146, 37.70997316074947],
            [55.71287093792049, 37.717161480909375],
            [55.7109447914831, 37.72209674549676],
            [55.7093335391386, 37.726323906904234],
            [55.7090791247672, 37.72731095982173],
            [55.70890951426613, 37.728405301099805],
            [55.708836823825045, 37.72947818470577],
            [55.708836823825045, 37.732332055097615],
            [55.708764133241914, 37.73544341755489],
            [55.70861875168065, 37.737803761487974],
            [55.70840067832016, 37.74065763187983],
            [55.70810157145192, 37.74410683420595],
            [55.7077259949117, 37.74726111200746],
            [55.70718079670852, 37.751853053840954],
            [55.70669616966368, 37.75586563852725],
            [55.7064175063769, 37.7581830671161],
            [55.70578747768386, 37.763397281441016],
            [55.705387646495176, 37.76691633966854],
            [55.705012043750855, 37.7707143476336],
            [55.70481818285457, 37.772903030189745],
            [55.70478183382899, 37.77425486353326],
            [55.70475760112639, 37.77537066248346],
            [55.70479395017462, 37.776658122810616],
            [55.70503627629498, 37.77811724451471],
            [55.7054603433743, 37.77921158579278],
            [55.70609037735183, 37.7805634191363],
            [55.70684155838418, 37.78210837152887],
            [55.70747157000318, 37.78322417047907],
            [55.70833176170184, 37.78459746149469],
            [55.70905866943138, 37.785584514412136],
            [55.710100546828755, 37.786871974739284],
            [55.71123931114489, 37.78820235041067],
            [55.71239015610755, 37.78940398004932],
            [55.713298693864736, 37.79030520227834],
            [55.71419509699418, 37.7911635091631],
            [55.714764423390335, 37.79165703562183],
            [55.71558811491593, 37.792343681129644],
            [55.71724755809994, 37.79348093775198],
            [55.718483009970626, 37.79438215998098],
            [55.71936718182298, 37.795133178505154],
            [55.71982742770244, 37.795712535652356],
            [55.72023922203582, 37.79654938486501],
            [55.720542009205, 37.797643726143086],
            [55.72071156898997, 37.79882389810963],
            [55.72079634859897, 37.800368850502224],
            [55.720735791749796, 37.801527564796636],
            [55.72060256634966, 37.80279356745168],
            [55.72037244867445, 37.80378062036915],
            [55.7197063108797, 37.80592638758108],
            [55.71864046668441, 37.80908066538259],
            [55.71770782905134, 37.811848705085964],
            [55.71662981742884, 37.815024440559576],
            [55.71512781882943, 37.81946617868826],
            [55.71352885335858, 37.82416540888231],
            [55.712014620917365, 37.82858568933883],
            [55.70957961156479, 37.835774009498756],
            [55.70869521719883, 37.83834893015301],
            [55.70715656540029, 37.841975276741145],
            [55.70537553024711, 37.84568745401775],
            [55.70359441356761, 37.8489919355241],
            [55.701994974013466, 37.851867263588026],
            [55.700359115562044, 37.85461384561927],
            [55.69918367851483, 37.85635191706094],
            [55.697838547209905, 37.85793978479775],
            [55.696832697928265, 37.8586693456498],
            [55.69616615697015, 37.8590341260758],
            [55.69552384307192, 37.85933453348545],
            [55.694760324084406, 37.85967785623937],
            [55.6939361915453, 37.85991389063266],
            [55.69311204155795, 37.86010700968174],
            [55.69229999435796, 37.86012846735387],
            [55.69147580973014, 37.85999972132116],
            [55.69065160764731, 37.85972077158361],
            [55.68970617789614, 37.859355991157585],
            [55.688797089176546, 37.85890538004306],
            [55.68770615469342, 37.85824019220738],
            [55.68680914121938, 37.857467716011094],
            [55.686033329117365, 37.85680252817539],
            [55.68502717476912, 37.85568672922519],
            [55.68403311723823, 37.85446364191441],
            [55.68292992611926, 37.853197639259385],
            [55.68188732117899, 37.85197455194858],
            [55.68111141101501, 37.85111624506382],
            [55.6806022115626, 37.85064417627719],
            [55.679983888999004, 37.85025793817906],
            [55.67937768087753, 37.85010773447422],
            [55.67872296550843, 37.85027939585117],
            [55.67811673776113, 37.85083729532627],
            [55.67743775148218, 37.851910178932236],
            [55.676734503209296, 37.85330492761994],
            [55.67601911694542, 37.854785506996166],
            [55.675315843066066, 37.856223171028134],
            [55.67458830500191, 37.85774666574859],
            [55.6740426425367, 37.858819549354564],
          ]}
          options={{
            strokeColor: '800080',
            strokeWidth: 8,
            strokeOpacity: 0.8,
            //strokeStyle: {
            //  style: 'dot',
            //  offset: 10
            //}
          }}
          // replace
          properties={{
            balloonContentBody: `Таганско-Краснопресненская линия`,
            balloonContentFooter: 'Подпись',
          }}
        />

        <Polyline
          geometry={[
            [55.77168923182107, 37.65844560079943],
            [55.77218514438308, 37.658123735717645],
            [55.77258428891612, 37.65782332830799],
            [55.77301971463445, 37.657501463226204],
            [55.77344304051073, 37.65713668280018],
            [55.77383007727136, 37.65679336004626],
            [55.774265489013644, 37.656385664276],
            [55.7748097468223, 37.65580630712879],
            [55.77525724196947, 37.655291322997925],
            [55.7758498627365, 37.65456176214588],
            [55.776176405006396, 37.654132608703485],
            [55.7765029445287, 37.65368199758899],
            [55.77685366910333, 37.65312409811389],
            [55.77716810913059, 37.65256619863879],
            [55.7776216238818, 37.65166497640972],
            [55.778032806036954, 37.65069938116435],
            [55.77844398383524, 37.64949775152568],
            [55.77871003773602, 37.648553613952444],
            [55.77891562359179, 37.64763093405132],
            [55.77908492877281, 37.64679408483867],
            [55.77919376742768, 37.64578557424907],
            [55.779302605777275, 37.64477706365947],
            [55.77945981618774, 37.642416719726356],
            [55.779556560739145, 37.64003491812113],
            [55.77961702596127, 37.63778186254862],
            [55.779641212017445, 37.635593179992426],
            [55.77962911898809, 37.632224325469736],
            [55.779556560732836, 37.63027167730689],
            [55.77939935071433, 37.625207666686784],
            [55.779193767415066, 37.61941409521456],
            [55.77921795374136, 37.61831975393649],
            [55.77929051262979, 37.61188245230074],
            [55.77941144380899, 37.60699010305759],
            [55.779580746820365, 37.60113215856902],
            [55.77954446766446, 37.598364118865646],
            [55.779278419484825, 37.59533858709685],
            [55.77873422434488, 37.59190535955779],
            [55.77799652541488, 37.58885837011687],
            [55.77741603114512, 37.586970094970376],
            [55.77690809154133, 37.58555388861052],
            [55.77640014528906, 37.5843093436276],
            [55.775759156006075, 37.582936052611984],
            [55.77537213848291, 37.58220649175993],
            [55.774706943097485, 37.581112150481864],
            [55.77329185317107, 37.57939553671232],
            [55.77169527951666, 37.57821536474577],
            [55.770074448176715, 37.57742143087736],
            [55.76846564566655, 37.57709956579557],
            [55.76668741794257, 37.577078108123466],
            [55.76541720541647, 37.57718539648405],
            [55.76288875393413, 37.57739997320525],
            [55.76195717766935, 37.57744288854949],
            [55.76106187552201, 37.577357057861015],
            [55.760372236814106, 37.577271227172545],
            [55.759755181277825, 37.577078108123466],
            [55.759089718239665, 37.57682061605802],
            [55.75870253449702, 37.5765631239926],
            [55.75830324721912, 37.57632708959929],
            [55.757371560938175, 37.57579064779632],
            [55.75582273428108, 37.57435298376433],
            [55.75420124019404, 37.57267928533905],
            [55.75326945550205, 37.57156348638885],
            [55.75297902452876, 37.57124162130701],
            [55.74951788836728, 37.56774402075158],
            [55.74904589135952, 37.56737924032556],
            [55.748525477754924, 37.56714320593227],
            [55.74794454270574, 37.56695008688319],
            [55.74735149588235, 37.56686425619472],
            [55.7468915757866, 37.566885713866824],
            [55.746407443487925, 37.56695008688319],
            [55.745632819261424, 37.56710029058803],
            [55.74464030940014, 37.567507986358294],
            [55.74409562857326, 37.56798005514487],
            [55.74358725291826, 37.568409208587234],
            [55.7431878102373, 37.568902735045995],
            [55.742631004497184, 37.569675211242284],
            [55.74185630497681, 37.5709412138973],
            [55.74103316981283, 37.57248616628989],
            [55.740524754089044, 37.573559049895835],
            [55.740004226337604, 37.57484651022298],
            [55.73925368567112, 37.57684207373008],
            [55.73895104420718, 37.57782912664754],
            [55.73855155386592, 37.57924533300741],
            [55.73811574153729, 37.58087611608847],
            [55.73754675697505, 37.58330083303793],
            [55.73530705596162, 37.59284949713095],
            [55.735040704551444, 37.59385800772055],
            [55.734701709207755, 37.59503817968709],
            [55.73433849662999, 37.596132520965185],
            [55.73399949516178, 37.59701228552205],
            [55.733369913162335, 37.598685983947355],
            [55.73274032096528, 37.60048842840537],
            [55.732231796746106, 37.6018831770931],
            [55.73150532202923, 37.60407185964922],
            [55.73007654882429, 37.608470682433634],
            [55.72919262046403, 37.611217264464884],
            [55.728865682965264, 37.612612013152635],
            [55.72867194092784, 37.61387801580766],
            [55.72857506954707, 37.61503673010209],
            [55.72853874271705, 37.61610961370806],
            [55.7285629606075, 37.61735415869097],
            [55.72864772310528, 37.6187489073787],
            [55.728877791810554, 37.62151694708208],
            [55.72919262045779, 37.6253578703914],
            [55.7293984685835, 37.627546552947564],
            [55.729592207000245, 37.62891984396319],
            [55.729870704282824, 37.630293134978814],
            [55.730185524895454, 37.63179517202715],
            [55.730476126275, 37.63299680166582],
            [55.73088780784402, 37.6343057196651],
            [55.731408457702436, 37.63597941809038],
            [55.73179591353648, 37.63698792867998],
            [55.73219547332054, 37.63784623556474],
            [55.732655567371985, 37.63885474615434],
            [55.733212516050344, 37.639949087432434],
            [55.73407213856371, 37.64155841284137],
            [55.734871207230434, 37.642931703856995],
            [55.735863966629616, 37.64454102926593],
            [55.73691723241473, 37.646107439330606],
            [55.73844260121693, 37.64829612188677],
            [55.7399436993711, 37.650377516082315],
            [55.7404642279333, 37.65110707693439],
            [55.74074264732534, 37.65149331503252],
            [55.74145684447012, 37.65230870657304],
            [55.74378092058122, 37.654282812408],
            [55.74457978964221, 37.65490508489947],
            [55.745475471784424, 37.65548444204667],
            [55.746443753575775, 37.656085256866014],
            [55.74746042348478, 37.656664614013245],
            [55.749953572772704, 37.657758955291264],
            [55.75186568565361, 37.65850997381545],
            [55.75369299670685, 37.65913224630689],
            [55.755241908297165, 37.659497026732915],
            [55.75698436000205, 37.65990472250317],
            [55.76117076464848, 37.66033387594557],
            [55.76326379772661, 37.66056991033883],
            [55.76517525537993, 37.660548452666724],
            [55.766760000178756, 37.660355333617645],
            [55.76819952143719, 37.660012010863724],
            [55.76914304422987, 37.65971160345407],
            [55.77000187196193, 37.659346823028045],
            [55.77080020182658, 37.65896058492989],
            [55.77144127301324, 37.65859580450387],
            [55.77168545179308, 37.658447612455795],
            [55.77168923182107, 37.65844560079943],
          ]}
          options={{
            strokeColor: '8D5B2D',
            strokeWidth: 8,
            strokeOpacity: 0.8,
            //strokeStyle: {
            //  style: 'dot',
            //  offset: 10
            //}
          }}
          // replace
          properties={{
            balloonContentBody: `Кольцевая линия`,
            balloonContentFooter: 'Подпись',
          }}
        />

        <Polyline
          geometry={[
            [55.77603127530682, 37.51467919760088],
            [55.775849862595436, 37.513370279601624],
            [55.7756805432997, 37.51281238012652],
            [55.775402374281555, 37.51206136160234],
            [55.775087919947104, 37.511353258422425],
            [55.77479765214617, 37.51079535894732],
            [55.77442271969016, 37.51023745947222],
            [55.77398730971182, 37.50970101766925],
            [55.77357608469146, 37.50925040655473],
            [55.773019714487084, 37.50879979544023],
            [55.772620574431826, 37.508563761046915],
            [55.7721125720614, 37.508370641997864],
            [55.77167713615989, 37.50828481130934],
            [55.7712537910229, 37.5082418959651],
            [55.77087882433131, 37.50828481130934],
            [55.7705885250501, 37.508327726653576],
            [55.7702861276568, 37.50843501501418],
            [55.769911151617315, 37.508585218719],
            [55.76953617195551, 37.508799795440204],
            [55.76929424767067, 37.508992914489255],
            [55.768955551138866, 37.50931477957104],
            [55.76859265871826, 37.509765390685565],
            [55.76826605263916, 37.5102803748164],
            [55.76783057359249, 37.511009935668476],
            [55.767370895965584, 37.51184678488111],
            [55.76627006688554, 37.51394963674875],
            [55.76579827344102, 37.51497960501045],
            [55.76538696165755, 37.51592374258371],
            [55.76499984070416, 37.51665330343576],
            [55.76470949745595, 37.517168287566626],
            [55.76421348938387, 37.51789784841867],
            [55.763584396860374, 37.5187132399592],
            [55.76281011514605, 37.51959300451608],
            [55.7618543398553, 37.52060151510568],
            [55.76123730787108, 37.52118087225289],
            [55.76070495867244, 37.521631483367415],
            [55.76017260217539, 37.52203917913767],
            [55.7585633892932, 37.52313352041569],
            [55.75736551085087, 37.52390599661198],
            [55.7567847080025, 37.52429223471013],
            [55.75637330072945, 37.52452826910345],
            [55.755865085728054, 37.524850134185236],
            [55.75528426044018, 37.52515054159489],
            [55.754521914061286, 37.525515322020915],
            [55.753868462403375, 37.52572989874212],
            [55.7530213790767, 37.52590156011907],
            [55.75225898828722, 37.52590156011907],
            [55.75153288790866, 37.5258157294306],
            [55.750806773956924, 37.52577281408636],
            [55.750056441940956, 37.52560115270936],
            [55.74959655386803, 37.52557969503723],
            [55.749221377986586, 37.52560115270936],
            [55.748858301102956, 37.52568698339783],
            [55.74842260436329, 37.52588010244691],
            [55.74808372574294, 37.5260946791681],
            [55.7477327411984, 37.526566747954725],
            [55.74739385656031, 37.527296308806775],
            [55.74716389744376, 37.52813315801942],
            [55.74700655621133, 37.52890563421571],
            [55.74689762729252, 37.529978517821675],
            [55.746873420824635, 37.5309011977228],
            [55.746909730520784, 37.531802419951795],
            [55.74698234981131, 37.532725099852925],
            [55.747115484824725, 37.53375506811462],
            [55.74739385656031, 37.53487086706483],
            [55.74775694713087, 37.53605103903138],
            [55.749039839968944, 37.539934877684956],
          ]}
          options={{
            strokeColor: '82C0C0',
            strokeWidth: 8,
            strokeOpacity: 0.8,
            //strokeStyle: {
            //  style: 'dot',
            //  offset: 10
            //}
          }}
          // replace
          properties={{
            balloonContentBody: `Большая кольцевая линия`,
            balloonContentFooter: 'Подпись',
          }}
        />

        <Polyline
          geometry={[
            [55.70302287289435, 37.92791104727161],
            [55.707372595156016, 37.919263605407586],
            [55.70780875217965, 37.91825509481799],
            [55.708147982039975, 37.917332414916864],
            [55.70847509374692, 37.91625953131092],
            [55.70874162717841, 37.91501498632801],
            [55.70885066304345, 37.91427469663981],
            [55.708911238411375, 37.913491491607545],
            [55.70893546852716, 37.912633184722786],
            [55.70891123840516, 37.911817793182216],
            [55.7088506630497, 37.9100153487242],
            [55.70853566967469, 37.90102458410626],
            [55.70843874812509, 37.89870715551738],
            [55.708402402481724, 37.896432642272764],
            [55.70845086333199, 37.89329982214336],
            [55.70851143930371, 37.89143300466898],
            [55.70860836067225, 37.88984513693217],
            [55.70875374227233, 37.88842893057232],
            [55.70888700826185, 37.88737750463848],
            [55.70928680349148, 37.88512444906597],
            [55.709492757006416, 37.883987192443655],
            [55.70978351305374, 37.88235640936259],
            [55.710110611003365, 37.88027501516702],
            [55.71057096643029, 37.877313856414595],
            [55.71088594332049, 37.87503934316998],
            [55.71101920200633, 37.87398791723612],
            [55.71106160238908, 37.873462204269124],
            [55.711079773985354, 37.872893575958045],
            [55.71107371677302, 37.87219620161418],
            [55.7110252591898, 37.871595386794844],
            [55.71092834384226, 37.870930198959165],
            [55.71080719931833, 37.87026501112346],
            [55.710673939906286, 37.86966419630412],
            [55.71052856548162, 37.869149212173255],
            [55.71035896129959, 37.86863422804242],
            [55.7101772417126, 37.868140701583656],
            [55.70998340655088, 37.86771154814129],
            [55.70964419269398, 37.86702490263348],
            [55.70928074599373, 37.86638117246991],
            [55.705537047380695, 37.86017990522742],
            [55.704810073848215, 37.85897827558875],
            [55.70461621194568, 37.85857057981848],
            [55.70439811615083, 37.85805559568762],
            [55.70422848524307, 37.85749769621254],
            [55.70401038727507, 37.85676813536047],
            [55.703816521388404, 37.855995659164186],
            [55.70364688794526, 37.85498714857459],
            [55.70353783748417, 37.854021553329225],
            [55.703453020241376, 37.85251951628089],
            [55.70336820281994, 37.849021915725466],
            [55.70341666993433, 37.848056320480104],
            [55.703525720734966, 37.847326759628054],
            [55.70374382141944, 37.846425537399064],
            [55.70401038726262, 37.84554577284217],
            [55.704252718262715, 37.84466600828528],
            [55.70443446552241, 37.843893532089],
            [55.70454351347078, 37.84314251356482],
            [55.70460409553225, 37.84230566435217],
            [55.70460409553225, 37.84170484953285],
            [55.70460409553225, 37.84082508497597],
            [55.70454351346455, 37.83990240507479],
            [55.7044223490586, 37.839172844222745],
            [55.70421636870268, 37.838250164321614],
            [55.7035014872439, 37.83501005583164],
            [55.703343969224555, 37.83413029127475],
            [55.70317433372143, 37.83318615370149],
            [55.70305316505214, 37.83224201612826],
            [55.70299258056976, 37.8311047595059],
            [55.70298046366323, 37.82943106108062],
            [55.70307739880994, 37.82788610868803],
            [55.70339243636911, 37.82563305311552],
            [55.70365900461938, 37.82415247373931],
            [55.70403462041806, 37.82237148695341],
            [55.70436176670794, 37.82108402662628],
            [55.70499181847352, 37.819088463119186],
            [55.70560974395579, 37.81713581495636],
            [55.708335768639344, 37.808896068862545],
            [55.71200651343579, 37.79769516401633],
            [55.71510759884783, 37.78846836500505],
            [55.715870718681344, 37.786472801497986],
            [55.71630678043549, 37.78542137556415],
            [55.7167186120477, 37.7846059840236],
            [55.717300013958926, 37.783490185073425],
            [55.717772396612325, 37.78265333586077],
            [55.71823266137136, 37.78192377500872],
            [55.71952864076666, 37.779735092452526],
            [55.72003733115891, 37.778683666518695],
            [55.72041278884705, 37.7776966136012],
            [55.72071557466483, 37.77664518766736],
            [55.72110313707156, 37.77533626966811],
            [55.72201147132111, 37.77211761885024],
            [55.723840186596306, 37.765744690230854],
            [55.72530551888061, 37.760702137282834],
            [55.72595945100695, 37.757783893874645],
            [55.72777587142276, 37.7496943514857],
            [55.72841765301579, 37.746776108077476],
            [55.72886568294256, 37.744565967849205],
            [55.72915629418836, 37.74291372709603],
            [55.72987070426011, 37.73885822706553],
            [55.73028239224063, 37.73639059477181],
            [55.730754617202535, 37.7342233698878],
            [55.73108153881581, 37.73287153654428],
            [55.73171115788006, 37.730597023299666],
          ]}
          options={{
            strokeColor: 'DE64A1',
            strokeWidth: 8,
            strokeOpacity: 0.8,
            //strokeStyle: {
            //  style: 'dot',
            //  offset: 10
            //}
          }}
          // replace
          properties={{
            balloonContentBody: `Некрасовская линия`,
            balloonContentFooter: 'Подпись',
          }}
        />

        <Polyline
          geometry={[
            [55.714065855621826, 37.45225882940653],
            [55.71261221368387, 37.452945474914344],
            [55.71136446102959, 37.45356774740581],
            [55.709498814446086, 37.454533342651175],
            [55.707948079062334, 37.455305818847464],
            [55.70625189146803, 37.45618558340435],
            [55.70594899304494, 37.45633578710917],
            [55.70540376994128, 37.45667910986308],
            [55.70494335337071, 37.45704389028911],
            [55.70430118431886, 37.45758033209208],
            [55.70354995424194, 37.45822406225565],
            [55.70285929443374, 37.458953623107675],
            [55.699745114669895, 37.46208644323708],
            [55.69894532400149, 37.462944750121835],
            [55.69720026914298, 37.46489739828466],
            [55.69623076041354, 37.466034654907],
            [55.695806592750706, 37.46661401205421],
            [55.69538242046557, 37.46725774221778],
            [55.69495824355811, 37.46800876074196],
            [55.694473464289544, 37.4690601866758],
            [55.69419471347631, 37.469639543823],
            [55.69390384093431, 37.470390562347184],
            [55.69352812735074, 37.47152781896949],
            [55.69306757026905, 37.473180059722665],
            [55.69271608882408, 37.474767927459446],
            [55.69241308503668, 37.4760768454587],
            [55.69218280058092, 37.47693515234348],
            [55.69185555190573, 37.47805095129366],
            [55.691576782345976, 37.478887800506314],
            [55.69129801079003, 37.47963881903049],
            [55.690982875323044, 37.4803254645383],
            [55.690388959233566, 37.48154855184909],
            [55.6896738237637, 37.48285746984836],
            [55.688788976950086, 37.484316591552464],
            [55.68781925871697, 37.4859259169614],
            [55.68720105073101, 37.486934427551],
            [55.68669193090696, 37.4875137846982],
            [55.68611007152807, 37.48805022650117],
            [55.684752365830434, 37.48918748312351],
            [55.68363707216462, 37.490088705352505],
            [55.68303092090526, 37.490603689483365],
            [55.68260660940992, 37.490989927581495],
            [55.682145923123265, 37.49150491171236],
            [55.68173372551054, 37.49208426885959],
            [55.68110329719345, 37.49339318685885],
            [55.68077595538217, 37.4942300360715],
            [55.68054560209225, 37.49508834295626],
            [55.68040011510192, 37.496032480529514],
            [55.68031524743992, 37.49708390646335],
            [55.680266751550015, 37.498306993774136],
            [55.680194007601955, 37.499165300658895],
            [55.680109139490824, 37.49995923452731],
            [55.679890906355865, 37.5009462874448],
            [55.67972116862771, 37.50143981390354],
            [55.679442312182694, 37.501997713378636],
            [55.67911495641706, 37.502619985870076],
            [55.67732051297374, 37.506031755737034],
            [55.67705376432295, 37.50652528219577],
            [55.67534410459742, 37.50955081396456],
            [55.674580189815266, 37.51113868170134],
            [55.67418003800477, 37.51214719229094],
            [55.67361011772412, 37.51369214468351],
            [55.672991684157374, 37.51553750448576],
            [55.67261576876057, 37.51676059179657],
            [55.672361114334095, 37.51738286428801],
            [55.6720579521322, 37.51811242514006],
            [55.67160926774398, 37.51897073202482],
            [55.671039309853825, 37.520000700286545],
            [55.670323818996984, 37.52126670294157],
            [55.669341513536914, 37.52304768972748],
            [55.66883216021498, 37.523841623595864],
            [55.66822577853075, 37.524807218841225],
            [55.66729193227412, 37.52613759451259],
            [55.66658850083949, 37.52710318975795],
            [55.66610336831345, 37.527746919921526],
            [55.66519372854789, 37.52881980352747],
            [55.66424768066052, 37.529849771789145],
            [55.66336225606845, 37.530708078673925],
            [55.662840695181494, 37.531265978149],
            [55.66235551599681, 37.53188825064047],
            [55.66183394163661, 37.53261781149252],
            [55.6613002304146, 37.533454660705175],
            [55.66086355216035, 37.53429150991783],
            [55.66046325946263, 37.53510690145835],
            [55.66008722318341, 37.535943750671],
            [55.65977183511955, 37.53673768453939],
            [55.65928661773032, 37.538153890899245],
            [55.65798863152354, 37.541951898864355],
            [55.65770961949857, 37.54298186712606],
            [55.65750339193564, 37.544204954436864],
            [55.65738208109484, 37.54564261846883],
            [55.657406343293204, 37.54735923223837],
            [55.657612571369576, 37.54903293066365],
            [55.65794010783657, 37.55027747564657],
            [55.658546649575875, 37.55210137777666],
            [55.65921383459478, 37.553817991546204],
            [55.65960200971424, 37.55504107885698],
            [55.659856747535464, 37.556071047118714],
            [55.65996592037777, 37.5568649809871],
            [55.66007509291428, 37.5579807799373],
            [55.66007509291428, 37.55928969793656],
            [55.65999018096788, 37.560491327575235],
            [55.65973544401253, 37.5619075339351],
            [55.659359400711416, 37.56319499426225],
            [55.65637518653277, 37.5717566054378],
            [55.65243227683256, 37.58332229071001],
            [55.65192270248164, 37.584995989135315],
            [55.651655779925484, 37.58645511083941],
            [55.65155871672471, 37.58804297857622],
            [55.65160724835531, 37.589459184936075],
            [55.651825639945386, 37.59117579870561],
            [55.65215322503679, 37.59327865057329],
            [55.65305103672728, 37.599158052733934],
            [55.65316022863154, 37.600252394012],
            [55.65319662585885, 37.601282362273736],
            [55.653208758262444, 37.60235524587968],
            [55.65330581734917, 37.61789060049394],
            [55.65330581734302, 37.622847322753465],
            [55.65325728782676, 37.62413478308061],
            [55.65306316915767, 37.62623763494828],
            [55.65282051946212, 37.62834048681596],
            [55.652323082864825, 37.63246035986285],
            [55.651874171212874, 37.63638711386064],
            [55.65175284285378, 37.637781862548394],
            [55.65172857713665, 37.63900494985918],
            [55.65174070999711, 37.64046407156327],
            [55.651910569646994, 37.64218068533281],
            [55.652128959538025, 37.64327502661089],
            [55.65243227679556, 37.64428353720049],
            [55.65275985678989, 37.64505601339677],
            [55.6531116989032, 37.645656828216104],
            [55.65366978677697, 37.64649367742876],
            [55.654191470391, 37.64726615362504],
            [55.657539785058745, 37.65200829916338],
            [55.65935940066208, 37.65411115103105],
            [55.66031769740065, 37.65496945791581],
            [55.665509072670055, 37.65923953466752],
            [55.66622465193717, 37.65990472250317],
            [55.66670978295326, 37.66039824896193],
            [55.66736470024443, 37.66110635214185],
            [55.6680074787341, 37.661900286010265],
            [55.668565353350175, 37.66269421987868],
            [55.66909896508007, 37.6635096114192],
            [55.6696325695021, 37.6645181220088],
            [55.67012978522703, 37.66556954794264],
            [55.670578486653895, 37.66666388922071],
            [55.67105143661792, 37.66808009558057],
            [55.67145162056477, 37.66951775961256],
            [55.67208220510262, 37.67177081518502],
            [55.67411940849144, 37.67947411947581],
            [55.676641512703895, 37.68867946081491],
            [55.677162888880744, 37.69035315924021],
            [55.67752663556895, 37.691340212157684],
            [55.67792675300263, 37.69226289205881],
            [55.67847236106106, 37.69333577566477],
            [55.67893309080291, 37.69408679418893],
            [55.679684796043915, 37.69522405081121],
            [55.68044861070853, 37.69627547674504],
            [55.68106692589422, 37.69717669897404],
            [55.681685231263494, 37.69809937887516],
            [55.6822914034686, 37.699129347136896],
            [55.68278845763631, 37.700137857726496],
            [55.68314002870483, 37.70106053762762],
            [55.683576457339846, 37.70217633657782],
            [55.684061372309, 37.70357108526555],
            [55.6852130211543, 37.70683265142766],
            [55.6883647280591, 37.71582341604557],
            [55.68888594730308, 37.71730399542175],
            [55.68928594804196, 37.71822667532288],
            [55.689698065717145, 37.718999151519185],
            [55.690134420853745, 37.71951413565003],
            [55.69065561641301, 37.72007203512513],
            [55.69141921591147, 37.72082305364931],
            [55.691988875623984, 37.721466783812886],
            [55.692425205085286, 37.72206759863222],
            [55.69286152965617, 37.72288299017274],
            [55.69346752788544, 37.724191908171996],
            [55.69379476301012, 37.72507167272889],
            [55.69444922500704, 37.72691703253114],
            [55.695079437271325, 37.72863364630068],
            [55.69530970459199, 37.72904134207094],
            [55.695612685833325, 37.729384664824835],
            [55.695976260209946, 37.72964215689028],
            [55.69642466392459, 37.72979236059509],
            [55.69695789405781, 37.729899648955694],
            [55.69760018429191, 37.72992110662783],
            [55.698278819061144, 37.72985673361146],
            [55.699224040628295, 37.729663614562384],
            [55.70003594339006, 37.729470495513304],
            [55.70239884757648, 37.72891259603818],
            [55.703428786556884, 37.72882676534971],
            [55.707402883779594, 37.72844052725152],
            [55.70909902119736, 37.72833323889092],
            [55.710589138045336, 37.72882676534963],
            [55.711740002242244, 37.729127172759284],
            [55.71233359257138, 37.729191545775656],
            [55.71299985664149, 37.729191545775656],
            [55.71347229152187, 37.72910571508718],
            [55.714150649520725, 37.72882676534963],
            [55.71528929527489, 37.7283332388909],
            [55.71608875004012, 37.728118662169685],
            [55.71676706240953, 37.728118662169685],
            [55.717445362949356, 37.7282474082024],
            [55.71800252953706, 37.72848344259571],
            [55.71843856739076, 37.72878385000539],
            [55.71889882426608, 37.72914863043142],
            [55.719407522896475, 37.72972798757862],
            [55.719904103236566, 37.7303717177422],
            [55.721042580570696, 37.73185229711842],
            [55.72167236222437, 37.73256040029834],
            [55.72238690982013, 37.73328996115042],
            [55.72301666970236, 37.73382640295338],
            [55.72369486124635, 37.73429847174001],
            [55.725789913981174, 37.73588633947682],
            [55.7265286050724, 37.73640132360769],
            [55.72704931331421, 37.73661590032887],
            [55.72750946828149, 37.73670173101734],
            [55.72805438159399, 37.73670173101734],
            [55.72851452466565, 37.73650861196826],
            [55.728926227008095, 37.73622966223074],
            [55.72935003368883, 37.735800508788344],
            [55.72966485851799, 37.735371355345954],
            [55.72995546378971, 37.73487782888722],
            [55.73034293410608, 37.73410535269094],
            [55.73074250882494, 37.7330539267571],
            [55.731226836311194, 37.73146605902027],
            [55.73275242844536, 37.72635913305594],
            [55.733466772436884, 37.72408461981129],
            [55.733757349287394, 37.7232048552544],
            [55.734108460083526, 37.722453836730246],
            [55.73435060360598, 37.722067598632094],
            [55.73468960201191, 37.72170281820607],
            [55.73513755965628, 37.72129512243581],
            [55.73564604586102, 37.721016172698256],
            [55.73637244318513, 37.72080159597708],
            [55.737062508068156, 37.720715765288574],
            [55.73811574136644, 37.72075868063284],
            [55.73921736864902, 37.72090888433765],
            [55.74027054355558, 37.7210805457146],
            [55.744507165715035, 37.72183156423878],
            [55.74622589212463, 37.722067598632044],
            [55.74683105938328, 37.72208905630417],
            [55.74739990801136, 37.722067598632044],
            [55.7479324396039, 37.72198176794357],
            [55.748549682997535, 37.72178864889452],
            [55.7493968638443, 37.72150969915697],
            [55.75029243493623, 37.72110200338668],
            [55.751284800409145, 37.72054410391161],
            [55.7521198200069, 37.72009349279708],
            [55.75475787991492, 37.71848416738815],
            [55.75566542791001, 37.71790481024094],
            [55.756173645524086, 37.71736836843797],
            [55.75659715512229, 37.716681722930154],
            [55.757190060800994, 37.71556592397995],
            [55.76002136308529, 37.709815267851994],
            [55.76039643465594, 37.70912862234418],
            [55.760819898204076, 37.708527807524845],
            [55.761279653399036, 37.70801282339398],
            [55.76176360035492, 37.70762658529582],
            [55.762344328733896, 37.70734763555827],
            [55.76298553956956, 37.70713305883709],
            [55.763675131857774, 37.70696139746014],
            [55.76596158707505, 37.70653224401775],
            [55.76655434978053, 37.70642495565717],
            [55.76724387868283, 37.70629620962443],
            [55.76868338199861, 37.70603871755902],
            [55.774029641296416, 37.70507312231366],
            [55.776049416369524, 37.70457959585487],
            [55.77690809129523, 37.704343561461556],
            [55.7778876831495, 37.70406461172401],
            [55.77883097045293, 37.70374274664222],
            [55.779290512371055, 37.703528169921036],
            [55.78020957988072, 37.70305610113441],
            [55.78169697196286, 37.70219779424965],
            [55.782192756646786, 37.7017471831351],
            [55.78247087699194, 37.701318029692736],
            [55.78277317945373, 37.700781587889736],
            [55.78305129563827, 37.70005202703769],
            [55.783281042287015, 37.69930100851354],
            [55.78354706302277, 37.69820666723544],
            [55.78380099020537, 37.696833376219814],
            [55.78464740214437, 37.69211268835361],
            [55.784828773722545, 37.69119000845249],
            [55.785034327159885, 37.69026732855136],
            [55.78533660964812, 37.68900132589634],
            [55.78569934552494, 37.68764949255282],
            [55.78608626005521, 37.68638348989781],
            [55.78644898892263, 37.68528914861973],
            [55.78687216830837, 37.68421626501377],
            [55.78730743372413, 37.68327212744053],
            [55.78779105623587, 37.68237090521151],
            [55.78841975648715, 37.681469682982524],
            [55.7891814373715, 37.68052554540929],
            [55.790378334257426, 37.679023508360935],
            [55.79221592223341, 37.676899198821104],
            [55.7927599282889, 37.67621255331329],
            [55.793183038834464, 37.67559028082182],
            [55.79361823343712, 37.674882177641905],
            [55.794138042812456, 37.67389512472441],
            [55.794585315070314, 37.67292952947905],
            [55.79498422921217, 37.671899561217344],
            [55.79543149171088, 37.67050481252959],
            [55.79587874905123, 37.66861653738311],
            [55.796120507628, 37.667114500334776],
            [55.79631391340425, 37.66548371725373],
            [55.79642270372951, 37.66370273046782],
            [55.796458967097095, 37.66209340505886],
            [55.79641061592432, 37.66007638387966],
            [55.796301825565166, 37.65820956640528],
            [55.79615677127818, 37.65668607168484],
            [55.79595127677618, 37.6548621695547],
            [55.795745781185225, 37.653360132506364],
            [55.79539522736344, 37.65117144995022],
            [55.79506884682461, 37.64941192083644],
            [55.79471828688996, 37.64756656103419],
            [55.79428310463147, 37.64535642080592],
            [55.7939083604402, 37.64340377264308],
            [55.793751208572644, 37.642309431365014],
            [55.7936786767267, 37.641494039824494],
            [55.79365449941457, 37.64097905569363],
            [55.79365449941457, 37.640292410185815],
            [55.79369076537711, 37.63945556097316],
            [55.7939083604402, 37.63308263235378],
            [55.793956714726534, 37.6317522566824],
            [55.794089688720526, 37.63061500006009],
            [55.79425892768982, 37.6297352355032],
            [55.794597403412794, 37.628340486815446],
            [55.79500840567632, 37.62701011114406],
            [55.79543149169187, 37.62567973547267],
            [55.7957216051491, 37.624778513243676],
            [55.795975452643795, 37.623770002654084],
            [55.796289737715966, 37.6224610846548],
            [55.79649523042445, 37.621130708983415],
            [55.79657984475261, 37.62022948675442],
            [55.79661610797958, 37.61907077245998],
            [55.79659193249869, 37.618105177214616],
            [55.79648314264187, 37.61276221685694],
            [55.79639852810271, 37.6105735343008],
            [55.796362264672254, 37.610080007842065],
            [55.796265562025276, 37.60920024328517],
            [55.796168859137154, 37.60851359777737],
            [55.79606006809344, 37.60776257925318],
            [55.79593918880504, 37.60703301840114],
            [55.79583039712334, 37.606475118926035],
            [55.795661165011815, 37.60572410040188],
            [55.79549193216178, 37.604994539549836],
            [55.795129287853385, 37.60351396017361],
            [55.79412595425712, 37.599565748503686],
            [55.793884183245936, 37.59844994955348],
            [55.79358196736242, 37.59707665853786],
            [55.79337645921644, 37.59583211355495],
            [55.793122594709075, 37.59411549978543],
            [55.792953350781, 37.59261346273707],
            [55.792844550722954, 37.59130454473782],
            [55.792772017181356, 37.58943772726344],
            [55.79277201717504, 37.58731341772366],
            [55.7928687285337, 37.58407330923365],
            [55.79295335077469, 37.58171296530056],
            [55.79307423937012, 37.579717401793474],
            [55.79325557155672, 37.5770566504507],
            [55.79338854795473, 37.57534003668116],
            [55.79371494263499, 37.572335962584496],
            [55.79395671469491, 37.56993270330714],
            [55.79418639676177, 37.56759381704616],
            [55.79421057374232, 37.56710029058742],
            [55.79423475070779, 37.5662848990469],
            [55.794246839184865, 37.565297846129404],
            [55.794246839184865, 37.56413913183497],
            [55.7941501312627, 37.563001875212656],
            [55.794017157475956, 37.56227231436061],
            [55.79382374024499, 37.56154275350856],
            [55.79350943518729, 37.56062007360744],
            [55.79315886115637, 37.55974030905055],
            [55.79277201716872, 37.558967832854265],
            [55.79205876344461, 37.55757308416651],
            [55.791176244926895, 37.555942301085466],
            [55.79081356026215, 37.55514836721705],
            [55.79049923080942, 37.55422568731593],
            [55.79019698854877, 37.55304551534938],
            [55.78994310322975, 37.55169368200589],
            [55.78965294654459, 37.54995561056422],
            [55.789544137221746, 37.54918313436791],
            [55.78938696767011, 37.54768109731955],
            [55.789229797481646, 37.54630780630393],
            [55.789084716742416, 37.54409766607565],
            [55.78893963546055, 37.541866068175274],
            [55.78878246345952, 37.539849046996075],
            [55.78869783211827, 37.53881907873434],
            [55.78860111035929, 37.538110975554424],
            [55.78852856888178, 37.537595991423565],
            [55.788395575820694, 37.53688788824362],
            [55.788250491961286, 37.536394361784886],
            [55.7880812267728, 37.535857919981915],
            [55.78783941807944, 37.535235647490445],
            [55.78752506452457, 37.534699205687474],
            [55.7871502550336, 37.53409839086814],
            [55.78660617029626, 37.53328299932762],
            [55.786122533012254, 37.53261781149191],
            [55.78568725430138, 37.531974081328336],
            [55.78476831653962, 37.530815367033924],
            [55.78199928032805, 37.527982954314155],
            [55.77884306360453, 37.524957422545356],
            [55.77828676891133, 37.52442098074236],
            [55.777851402284185, 37.52388453893939],
            [55.77757324882631, 37.523369554808525],
            [55.77730718713141, 37.522790197661315],
            [55.777137874193976, 37.52221084051411],
            [55.77695646665554, 37.5214383643178],
            [55.77678715218789, 37.5204298537282],
            [55.776654118873644, 37.519378427794365],
            [55.77586800361016, 37.51345611028946],
            [55.77554145874526, 37.51124597006118],
            [55.77542051549913, 37.50989413671766],
            [55.775372138097666, 37.50856376104628],
            [55.775432609840074, 37.50673985891616],
            [55.77560193021752, 37.50562405996595],
            [55.77583172097676, 37.503950361540674],
            [55.7760252279292, 37.50234103613174],
            [55.776146169278206, 37.501075033476674],
            [55.776170357504036, 37.50013089590342],
            [55.776121981037306, 37.49837136678966],
            [55.775662401589265, 37.48395181112555],
            [55.77555355302961, 37.480990652373116],
            [55.775468892827696, 37.480132345488336],
            [55.77532376062331, 37.479402784636285],
            [55.77505768350562, 37.47856593542363],
            [55.7748520772107, 37.47813678198127],
            [55.774525523797585, 37.47766471319464],
            [55.774078020213274, 37.47732139044075],
            [55.7735337521432, 37.47702098303107],
            [55.7686591889421, 37.47466063909793],
            [55.7635057592647, 37.47242904119754],
            [55.75808545202373, 37.47000432424806],
            [55.75716586054423, 37.46942496710085],
            [55.75639145083494, 37.46875977926515],
            [55.75577433204213, 37.46820187979007],
            [55.75470947655731, 37.467107538511975],
            [55.74919112140819, 37.46097064428591],
            [55.74277625853328, 37.453803781798115],
            [55.737408576774264, 37.44781187421342],
            [55.73582263402674, 37.446052345099645],
            [55.734793552586545, 37.44512966519852],
            [55.73407923297849, 37.44474342710039],
            [55.73330436296675, 37.444528850379186],
            [55.73260212367572, 37.44446447736284],
            [55.73192408749454, 37.44463613873979],
            [55.730822253475175, 37.44508674985428],
            [55.72909073690983, 37.445816310706306],
            [55.72240611734945, 37.448648723426025],
            [55.71432733528748, 37.45214632398145],
            [55.714065855621826, 37.45225882940653],
          ]}
          options={{
            strokeColor: '82C0C0',
            strokeWidth: 8,
            strokeOpacity: 0.8,
            //strokeStyle: {
            //  style: 'dot',
            //  offset: 10
            //}
          }}
          // replace
          properties={{
            balloonContentBody: `Большая кольцевая линия`,
            balloonContentFooter: 'Подпись',
          }}
        />

        <Polyline
          geometry={[
            [55.537993422301746, 37.515923742583944],
            [55.53885735727216, 37.519099478057576],
            [55.539648266938826, 37.52242541723606],
            [55.54057300259517, 37.52641654425022],
            [55.54131520883404, 37.52948499136325],
            [55.541716724376876, 37.53083682470677],
            [55.54335924507373, 37.53590083532686],
            [55.54393106944176, 37.537681822112745],
            [55.54432039189142, 37.53886199407932],
            [55.54462454736102, 37.54002070837373],
            [55.545074693118885, 37.54178023748751],
            [55.54576815077799, 37.5446770232236],
            [55.546145289259535, 37.54660821371432],
            [55.54648592734539, 37.54828191213963],
            [55.54766597207042, 37.553066973022176],
            [55.5485661884574, 37.55669331961031],
            [55.54894329997927, 37.55810952597017],
            [55.549283913714575, 37.55926824026461],
            [55.549904309686944, 37.560984854034146],
            [55.5504395453686, 37.56225085668917],
            [55.55090178847559, 37.56310916357395],
            [55.55160730689612, 37.56426787786834],
            [55.55226415744592, 37.56510472708099],
            [55.553139941028846, 37.566027406982094],
            [55.5538210924872, 37.56656384878509],
            [55.55497659016472, 37.56729340963714],
            [55.558029372483624, 37.56888127737394],
            [55.55909961427732, 37.56948209219328],
            [55.55970769316268, 37.569825414947196],
            [55.5607414055711, 37.570490602782854],
            [55.5615805167396, 37.571112875274295],
            [55.56276010635206, 37.57199263983119],
            [55.56391534004635, 37.57282948904384],
            [55.56558124856962, 37.574095491698856],
            [55.568231964027106, 37.576112512878055],
            [55.57048128348606, 37.577743295959124],
            [55.57344775563514, 37.57965302877769],
            [55.57538070449973, 37.580811743072076],
            [55.57659634666631, 37.581434015563524],
            [55.577726859960976, 37.58188462667802],
            [55.57880871841727, 37.58203483038286],
            [55.57942864628094, 37.58194899969439],
            [55.579963478112596, 37.58171296530107],
            [55.58034028705491, 37.581434015563524],
            [55.580668472529275, 37.5810692351375],
            [55.58097234551188, 37.580618624023],
            [55.5812640613529, 37.580039266875794],
            [55.581592239071945, 37.579030756286194],
            [55.58181102268734, 37.578172449401414],
            [55.582224277286564, 37.57628417425492],
            [55.582540292561646, 37.57503962927201],
            [55.582844150987704, 37.57400966101031],
            [55.58319662380975, 37.573172811797654],
            [55.58358555564898, 37.57237887792924],
            [55.58408386891235, 37.57152057104448],
            [55.58449709948296, 37.570962671569376],
            [55.584934632972406, 37.57042622976641],
            [55.58597983203832, 37.56935334616044],
            [55.58686701396443, 37.56858086996416],
            [55.58824028325908, 37.56759381704669],
            [55.58941906885466, 37.56686425619464],
            [55.59317393231506, 37.564718488982685],
            [55.594279664756094, 37.56403184347487],
            [55.595093755453796, 37.563473943999774],
            [55.59628448442541, 37.562551264098644],
            [55.596940584885395, 37.56195044927931],
            [55.59748732685199, 37.561306719115734],
            [55.597912565313855, 37.560727361968524],
            [55.59831350018948, 37.56004071646071],
            [55.598763028345566, 37.55918240957593],
            [55.59930974480737, 37.55815244131423],
            [55.60029381515741, 37.55628562383984],
          ]}
          options={{
            strokeColor: 'A1B3D4',
            strokeWidth: 8,
            strokeOpacity: 0.8,
            //strokeStyle: {
            //  style: 'dot',
            //  offset: 10
            //}
          }}
          // replace
          properties={{
            balloonContentBody: `Бутовская линия`,
            balloonContentFooter: 'Подпись',
          }}
        />

        <Polyline
          geometry={[
            [55.87817680677236, 37.48084044866932],
            [55.87762193693917, 37.48114085607898],
            [55.87712737233208, 37.48137689047229],
            [55.87642773354607, 37.48163438253771],
            [55.87561951436073, 37.48189187460316],
            [55.874823341629586, 37.48210645132434],
            [55.87418397893121, 37.482299570373414],
            [55.87361698807089, 37.482449774078255],
            [55.87289315786963, 37.48266435079944],
            [55.87184358001009, 37.48296475820912],
            [55.871035264999364, 37.48315787725817],
            [55.87022693309246, 37.48320079260241],
            [55.869756403754636, 37.483179334930306],
            [55.86941858428919, 37.48309350424183],
            [55.86917728286429, 37.48296475820912],
            [55.86865847970238, 37.482642893127334],
            [55.86801901506669, 37.48163438253771],
            [55.867343342825166, 37.48006797247304],
            [55.86671592232296, 37.47867322378528],
            [55.86640220825501, 37.4779222052611],
            [55.866004029042834, 37.47699952535998],
            [55.865400719395524, 37.47573352270496],
            [55.86472500141835, 37.474338774017205],
            [55.86424233849302, 37.473373178771844],
            [55.86402513820528, 37.47307277136218],
            [55.86363900136033, 37.47266507559193],
            [55.86331319539914, 37.47245049887074],
            [55.86298738669376, 37.47227883749377],
            [55.86255297081783, 37.472128633788955],
            [55.8620823481135, 37.47206426077259],
            [55.8614789772963, 37.47210717611682],
            [55.86114108552792, 37.47215009146106],
            [55.86082732624321, 37.47227883749377],
            [55.860489428782984, 37.47247195654285],
            [55.859861897091726, 37.47290110998524],
            [55.85532405738044, 37.475969557098274],
            [55.853284269874166, 37.47732139044174],
            [55.85259626995917, 37.477793459228366],
            [55.8518599757985, 37.4782869856871],
            [55.85082189793769, 37.478995088867016],
            [55.848637015184835, 37.48045421057114],
            [55.84553451117992, 37.48257852011089],
            [55.843796034470174, 37.48384452276591],
            [55.8410312127771, 37.48592591696148],
            [55.83644286333134, 37.489466432861136],
            [55.83231288390059, 37.49266362600685],
            [55.83122597400007, 37.49352193289161],
            [55.83071873894037, 37.493822340301264],
            [55.83016318815716, 37.494015459350344],
            [55.829969951234155, 37.49405837469458],
            [55.829281536860485, 37.49395108633398],
            [55.82878635404304, 37.49380088262916],
            [55.827264533071, 37.49326444082614],
            [55.82662438416469, 37.49309277944919],
            [55.826177481487505, 37.4931142371213],
            [55.82580304554501, 37.49317861013767],
            [55.825223266622096, 37.49341464453098],
            [55.82385833614676, 37.49427295141574],
            [55.82271079100612, 37.49508834295626],
            [55.81959412849266, 37.497577432922085],
            [55.81605435886591, 37.500560049346646],
            [55.813517123672874, 37.502813104919156],
            [55.81166846199608, 37.5045297186887],
            [55.808514659131966, 37.50742650442476],
            [55.80794670567964, 37.50796294622776],
            [55.807269984318346, 37.508864168456746],
            [55.807004126265454, 37.509250406554905],
            [55.80654491260659, 37.51019454412813],
            [55.806121947844794, 37.511224512389866],
            [55.80549353453399, 37.513091329864224],
            [55.80453881020175, 37.5168678801572],
            [55.80346320651608, 37.521309618285855],
            [55.802121681455766, 37.527210478118604],
            [55.801614065273576, 37.52939916067477],
            [55.80128773707547, 37.53064370565769],
            [55.800816369273655, 37.53195262365694],
            [55.799837356291, 37.53429150991793],
            [55.798471531457814, 37.53755307608004],
            [55.79749245927459, 37.53987050466892],
            [55.79360010068318, 37.549204592040724],
            [55.7913273628566, 37.55463338308687],
            [55.78903031164565, 37.55986905508392],
            [55.78814772421958, 37.56171441488615],
            [55.78765201562565, 37.5626585524594],
            [55.78580212031148, 37.56615615301483],
            [55.78361356814713, 37.57031894140593],
            [55.78205369595203, 37.57330155783049],
            [55.78054213205262, 37.57617688589442],
            [55.779405397213054, 37.57834411077846],
            [55.77864352437331, 37.579803232482554],
            [55.77641828630783, 37.58409476690637],
            [55.77326161560423, 37.58986688070644],
            [55.77027403176943, 37.5953385870968],
            [55.76843540433352, 37.598600153258886],
            [55.76596763590742, 37.60297751837117],
            [55.764419152087534, 37.60600305013999],
            [55.7635118086567, 37.60799861364706],
            [55.761104221313055, 37.61316991262776],
            [55.759894322068305, 37.61570191793783],
            [55.7587327833263, 37.6179120581661],
            [55.757958404856566, 37.619006399444174],
            [55.757292911027285, 37.61984324865683],
            [55.755852985347246, 37.62110925131187],
            [55.75477603124517, 37.621989015868735],
            [55.753844260342696, 37.62278294973715],
            [55.752549424695594, 37.62392020635943],
            [55.749971726189784, 37.62615180425981],
            [55.74874937743987, 37.62718177252154],
            [55.74782956490148, 37.627846960357225],
            [55.747248619439354, 37.62819028311114],
            [55.74654663208949, 37.6284906905208],
            [55.7455662492191, 37.62879109793048],
            [55.74433165782041, 37.62907004766803],
            [55.7431696536083, 37.62922025137284],
            [55.740530806644095, 37.629327539733445],
            [55.739513955399126, 37.629370455077684],
            [55.73887235699455, 37.62956357412676],
            [55.73777071993178, 37.62994981222489],
            [55.736523774364535, 37.63074374609331],
            [55.735228361364534, 37.63185954504351],
            [55.73423558572401, 37.63295388632153],
            [55.73313381722494, 37.63428426199292],
            [55.73155980805122, 37.636387113860614],
            [55.72974356441427, 37.63876891546584],
            [55.72850847027424, 37.64039969854688],
            [55.724270103805665, 37.646236185363286],
            [55.723107499687856, 37.64775968008375],
            [55.72200541576885, 37.64881110601759],
            [55.720818521238805, 37.64986253195142],
            [55.71924401341043, 37.651128534606464],
            [55.71826294168336, 37.65190101080275],
            [55.7152832387866, 37.65423989706373],
            [55.71307859623036, 37.65599942617749],
            [55.71178240189554, 37.656900648406506],
            [55.71115246007524, 37.65726542883248],
            [55.710474049790555, 37.657522920897925],
            [55.70958967576361, 37.657673124602745],
            [55.70852355432129, 37.65775895529124],
            [55.707614905031875, 37.657716039947005],
            [55.70238067195676, 37.657522920897925],
            [55.701726343315485, 37.65748000555369],
            [55.70125376578781, 37.65754437857003],
            [55.700696359276364, 37.657716039947005],
            [55.700078355767985, 37.65820956640574],
            [55.69970270178104, 37.65857434683176],
            [55.69933916221227, 37.659153703978966],
            [55.69878172827806, 37.660205129912804],
            [55.697788021898155, 37.66200757437082],
            [55.69697607223543, 37.66303754263255],
            [55.69630953373877, 37.663616899779754],
            [55.69560662623179, 37.66402459555002],
            [55.69515821308715, 37.664196256926964],
            [55.69212825915949, 37.66468978338562],
            [55.68826169549625, 37.665204767516485],
            [55.68759500777061, 37.66514039450014],
            [55.681182101130744, 37.66417479925478],
            [55.67619894367945, 37.66353106909116],
            [55.67037839064208, 37.66213632040343],
            [55.66968714219557, 37.66187882833798],
            [55.668862478890965, 37.66149259023985],
            [55.66688564137472, 37.660398248961755],
            [55.664253744994944, 37.658660177520105],
            [55.660141809165616, 37.655441526702205],
            [55.659074333068695, 37.65443301611261],
            [55.65798256591156, 37.653231386473934],
            [55.656866505648956, 37.65168643408136],
            [55.65353026545245, 37.646794084838184],
            [55.651953034326425, 37.64451957159357],
            [55.65141918780202, 37.643875841429995],
            [55.65069120348866, 37.643339399626996],
            [55.65001173919937, 37.64312482290581],
            [55.649077456467914, 37.643103365233706],
            [55.648361562337264, 37.64321065359429],
            [55.647390837446004, 37.643532518676075],
            [55.64655356781741, 37.64394021444634],
            [55.645473584791404, 37.64460540228204],
            [55.64455132841965, 37.64524913244561],
            [55.643519831633945, 37.6460216086419],
            [55.64280383547558, 37.646751169493946],
            [55.642233456248206, 37.64750218801813],
            [55.64156598060277, 37.64883256368952],
            [55.640789267284056, 37.65057063513111],
            [55.63989117323496, 37.652179960540046],
            [55.639114426570515, 37.65327430181812],
            [55.63839834953686, 37.65411115103077],
            [55.63733027801033, 37.6551411192925],
            [55.63633500322084, 37.65602088384939],
            [55.63344613501668, 37.658617262175795],
            [55.63239006677972, 37.65960431509329],
            [55.63008361147619, 37.661792997649435],
            [55.62874823292485, 37.66299462728806],
            [55.62697575081899, 37.66451812200852],
            [55.62326056136798, 37.66765094213792],
            [55.620334297175944, 37.67029023580854],
            [55.61992144556569, 37.67084813528361],
            [55.61958144684321, 37.67166352682416],
            [55.61924144515929, 37.672972444823415],
            [55.6190714432068, 37.67445302419964],
            [55.61902287108439, 37.67627692632976],
            [55.61903501412066, 37.67960286550824],
            [55.61895001278745, 37.68144822531049],
            [55.61882858199035, 37.68284297399822],
            [55.61863429192928, 37.68410897665326],
            [55.618039272601806, 37.68640494757001],
            [55.617529248810605, 37.6877567809135],
            [55.616824919295595, 37.689344648650334],
            [55.616266304092754, 37.69039607458417],
            [55.615064039912504, 37.69224143438642],
            [55.612355773852386, 37.696125273039975],
            [55.61081331415793, 37.698378328612485],
            [55.610108863478395, 37.69955850057903],
            [55.609708049660696, 37.700631384185],
            [55.60929508566065, 37.70211196356122],
            [55.6090035790355, 37.70369983129803],
            [55.60890640967687, 37.70548081808391],
            [55.6089914328789, 37.7069184821159],
            [55.60928293959472, 37.70908570699994],
            [55.60963517397301, 37.71136022024456],
            [55.61109266175822, 37.72017932348548],
            [55.6120521448587, 37.72640204840005],
            [55.61284157529948, 37.7318522971183],
            [55.613120908401605, 37.73425555639565],
            [55.61338809471632, 37.73850417547523],
            [55.61391031722787, 37.74828887396156],
            [55.614238220814094, 37.7524087470084],
            [55.61467542132006, 37.75470471792515],
            [55.61510047267384, 37.756163839629245],
            [55.61571982493368, 37.75788045339879],
            [55.61631487961459, 37.75929665975865],
            [55.61752924877399, 37.761699919035976],
            [55.618269995415694, 37.76292300634678],
            [55.61897429885881, 37.76391005926425],
            [55.619787874949445, 37.76494002752598],
            [55.62051643616134, 37.7657554190665],
            [55.62159711024086, 37.76661372595123],
            [55.62289630801655, 37.76747203283599],
            [55.62378265177639, 37.76785827093415],
            [55.624523279755024, 37.76800847463896],
            [55.625992352674466, 37.76807284765533],
            [55.62817764809612, 37.767987016966856],
            [55.629816539349754, 37.76781535558991],
            [55.63070272594992, 37.76753640585236],
            [55.63120043836479, 37.767300371459044],
            [55.63164958802108, 37.76695704870512],
            [55.63274209262818, 37.76599145345976],
          ]}
          options={{
            strokeColor: '2DBE2C',
            strokeWidth: 8,
            strokeOpacity: 0.8,
            //strokeStyle: {
            //  style: 'dot',
            //  offset: 10
            //}
          }}
          // replace
          properties={{
            balloonContentBody: `Замоскворецкая линия`,
            balloonContentFooter: 'Подпись',
          }}
        />
        {crossways}
        {station_sq}
        {hermodors}

      </Map>
    </YMaps>
  );
}

export { MapPage }

// Здесь YMaps