import React from "react";
import { BrowserRouter, Route, Switch, useHistory, NavLink } from "react-router-dom";

import { UserContext } from "../../utils/context";
import YmapsComponent from "../map/YmapsComponent.tsx";
import api from '../../api'
import { logoutUser } from "../../utils/api";
import Header from "../header";
//import { Footer } from "../footer/footer";
import { SignUp } from "../sign-up/sign-up";
import { SignIn } from "../sign-in/sign-in";
import SingleCard from "../single-card/index";
import HermodoorsCard from "../hermodoors/index";
import { MapPage } from "../map/MapPage";
import { Mapex } from "../map/Mapex";
import  { EditCardPage }  from "../edit-card-page/edit-card-page"
//import { CardPage } from "../card-page/card-page";
//import { AddCardPage } from "../add-card-page/add-card-page";
//import { EditCardPage } from "../edit-card-page/edit-card-page";
import { getUser } from "../../utils/api";

import styles from "./app.module.css";

function App() {
  const [loggedIn, setLoggedIn] = React.useState(null)
  const [userState, setUserState] = React.useState({});
  const [currentCard, setCurrentCard] = React.useState({});


  const onSignOut = () => {
    logoutUser()
      .then(res => {
        localStorage.removeItem('auth_token')
        setLoggedIn(false)
        window.location.reload()
      })
      .catch(err => {
        const errors = Object.values(err)
        if (errors) {
          alert(errors.join(', '))
        }
      })
  }

  const authorization = ({
    email, password
  }) => {
    api.signin({
      email, password
    }).then(res => {
      if (res.auth_token) {
        localStorage.setItem('token', res.auth_token)
        api.getUserData()
          .then(res => {
            
            setLoggedIn(true)
          })
          .catch(err => {
            setLoggedIn(false)
            history.push('/signin')
          })
      } else {
        setLoggedIn(false)
      }
    })
      .catch(err => {
        const errors = Object.values(err)
        if (errors) {
          alert(errors.join(', '))
        }
        setLoggedIn(false)
      })
  }

  //const registration = ({
  //  email,
  //  password,
  //  username,
  //  first_name,
  //  last_name
  //}) => {
  //  api.signup({ email, password, username, first_name, last_name })
  //    .then(res => {
  //      history.push('/signin')
  //    })
  //    .catch(err => {
  //      const errors = Object.values(err)
  //      if (errors) {
  //        alert(errors.join(', '))
  //      }
  //      setLoggedIn(false)
  //    })
  //}

  React.useEffect(async () => {
    const token = localStorage.getItem("auth_token");
    if (token) {
      getUser().then((res) => {
        if (res && res.id) {
          
          setUserState({ id: res.id, is_staff: res.is_staff });
        }
      });
    }
  }, []);

  const history = useHistory()

  //React.useEffect(_ => {
  //  if (loggedIn) {
  //    // history.push('/recipes')
  //  }
  //}, [loggedIn])




  return (
    // className={styles.app} вставить в див ниже
    <div >
      <UserContext.Provider value={[userState, setUserState]}>
        <BrowserRouter>
          <main className={styles.content}>
            <Header loggedIn={loggedIn} onSignOut={onSignOut} />
            <Switch>
              <Route path="/signin">
                <SignIn onSignIn={authorization} render={() => <NavLink to='/' />} />
              </Route>
              <Route path="/signup">
                <SignUp />
              </Route>
              <Route
                exact
                path='/stations/:name'
              >
                <SingleCard
                  loggedIn={loggedIn}
                  
                />
              </Route>
              <Route
                exact
                path='/stations/:name/edit'
              >
                {userState.is_staff?<EditCardPage data={currentCard} setData={setCurrentCard} />:<div>Вы не можете редактировать содержимое станции из-за ограничения прав доступа.</div>}
                
              </Route>
              <Route
                exact
                path='/hermodoors/:name'
              >
                <HermodoorsCard
                  loggedIn={loggedIn}
                  
                />
              </Route>
              <Route path="/ex">
                <Mapex />
              </Route>
              <Route path="/">
                <MapPage />
              </Route>
            </Switch>
          </main>
        </BrowserRouter>
      </UserContext.Provider>
    </div>
  );
}

export default App;
