import styles from './style.module.css'
import { URL } from "../../utils/constants";
import { getUser, loginUser } from "../../utils/api";
import { Link, BrowserRouter, Route, NavLink } from 'react-router-dom'
//import { AccountMenu } from '../index.js'


import { useState, useEffect } from 'react'

const Header = ({ loggedIn, onSignOut }) => {
  //const handleLogout = () => {
  //  logoutUser().then(() => {
  //    setUser({ id: "" });
  //    setQueryPage(1);
  //  });
  //};
  const token = localStorage.getItem('auth_token')
  const [user, setUser] = useState(null)

  useEffect(() => {
    fetch(`${URL}/api/users/me/`,
      {
        mode: 'cors',
        method: 'GET',
        headers: {
          'authorization': token ? `Token ${token}` : null
        },
        //cache: 'no-cache'
      }
    )
      .then(response => response.json())
      .then(data => {
        setUser({ data });
      })
      .catch(error => console.error('Error:', error));
  }, []);


  return <header
    className={styles.headerstyle}
  >
    <span className={styles.smth}>{user?.data.username}</span>



    <div className={styles.container}>


      <div >
        <a href='/'><button className={`${styles.btn}`}><span>Главная</span></button></a>
      </div>


      <div >

        

        { window.location.pathname!='/signin'&&window.location.pathname!='/signup'? user?.data.username ? <button
          className={`${styles.btn}`}
          onClick={onSignOut}
        >
          <svg width="180px" height="60px" viewBox="0 0 180 60" className="border">
            <polyline points="179,1 179,29 1,29 1,1 179,1" className="bg-line" />
            <polyline points="179,1 179,29 1,29 1,1 179,1" className="hl-line" />
          </svg>
          <span>Выйти</span>

        </button> : <a href={`/signin`}><button
          className={`${styles.btn}`}
        >
          <svg width="180px" height="60px" viewBox="0 0 180 60" className="border">
            <polyline points="179,1 179,29 1,29 1,1 179,1" className="bg-line" />
            <polyline points="179,1 179,29 1,29 1,1 179,1" className="hl-line" />
          </svg>
          <span>Войти</span>

        </button></a>:null}



      </div>

    </div>

  </header>
}

export default Header
